import React from "react";

function ArticleSlider() {
	return (
		<div>
			<h1>This is ArticleSlider</h1>
		</div>
	);
}

export default ArticleSlider;
