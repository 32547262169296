import React from "react";
import "./Button.scss"

function Button({ onClose, onClick, isActive, isSelected, children }) {

	return (
		<div>
			<div className="button-close-container">
				{isSelected && 
					<div  className="close button-close" onClick={onClose} aria-label="Close">
						<span className={"button-close-span"} aria-hidden="true">&times;</span>
					</div>}
			</div>
			<div className={`button-container ${isSelected ? 'button-container-active' : 'button-container'}`} onClick={onClick}> 
				<button className={`button-button`} >
					<div className={`button-text ${isSelected ? 'button-active' : 'button-inactive'}`}>{children}</div>
				</button>
			</div>
		</div>
	);
}

export default Button;
