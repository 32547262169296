import React from "react"
import { useHistory } from "react-router-dom"

import Banner from "../../components/Banner/Banner"
import BannerBack from "../../components/Banner/BannerBack/BannerBack"

export const About = () => {
  const history = useHistory()

  return (
    <div>
      <Banner color='aqua' flipped hideDesktopWave>
        <div className='hide-desktop'>
          <BannerBack onClick={() => history.push("/more")} />
        </div>
      </Banner>
      <h1>About Helix</h1>
      <p>
        Helix was designed to help healthcare providers  learn about genetic testing and genetic counseling for prostate cancer and perform intake of clinical and family history information on their patients to facilitate collaboration with genetic counseling. Helix was
        developed by the Digital Innovation and Consumer Experience (DICE) Group
        of Thomas Jefferson University and Jefferson Health in Philadelphia
        under the leadership of Dr. Veda Giri and in collaboration with a team
        of investigators.
      </p>
      <p style={{ textDecoration: "underline" }}>
        Collaborative Team for Helix Development and Testing:
      </p>
      <p>
        Veda N. Giri, MD – Professor, Medical Oncology, Cancer
        Biology, and Urology, Director of Cancer Risk Assessment and Clinical
        Cancer Genetics, Sidney Kimmel Cancer Center, Thomas Jefferson
        University
      </p>
      <p>
        Stacy Loeb, MD - Professor of Urology and Population Health at New York
        University and the Manhattan Veterans Affairs Medical Center
      </p>
      <p>
        Heather H. Cheng, MD, PhD - Associate Professor, Division of Medical
        Oncology, University of Washington
      </p>
      <p style={{ textDecoration: "underline" }}>Funding Acknowledgements:</p>
      <p>
        Janssen Scientific Affairs, LLC <br />
        2019 Prostate Cancer Foundation VAlor Challenge Award{" "}
      </p>
    </div>
  )
}
