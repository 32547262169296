import React from "react";

function CategoryHeading() {
	return (
		<div>
			<h1>This is CategoryHeading</h1>
		</div>
	);
}

export default CategoryHeading;
