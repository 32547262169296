import React from "react"

import StyleTemplate from "../components/StyleTemplate/StyleTemplate"

export const StyleGuide = () => {
	return (
		<div>
			<h1>This is the StyleGuidePage</h1>
			<StyleTemplate></StyleTemplate>
		</div>
	)
}
