import React, { useState, useEffect } from "react"
import LIBRARY_CONTENT from "./LIBRARY_CONTENT"
import RectangleLink from "../../components/RectangleLink/RectangleLink"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import "./Home.scss"

function Home({ onClick, children }) {
  const [searchValue, setSearchValue] = useState("")
  const [searchData, setSearchData] = useState([])
  const [searchResults, setSearchResults] = useState([])

  const flipIcon = (categoryIndex, topicIndex) => {
    const isStartFlipped = categoryIndex % 2 === 0
    if (isStartFlipped) {
      return topicIndex % 2 !== 0
    } else {
      return topicIndex % 2 === 0
    }
  }

  useEffect(() => {
    let searchDataArray = []
    LIBRARY_CONTENT.forEach((category) => {
      category.topics.forEach((topic) => {
        let tags = topic.tags.map((tag) => tag.toUpperCase())
        searchDataArray.push({
          catName: category.name,
          catId: category.sectionId,
          topicName: topic.name,
          topicId: topic.topicId,
          color: category.color,
          tags,
        })
      })
    })
    setSearchData(searchDataArray)
  }, [])

  useEffect(() => {
    if (searchValue.length) {
      let searchFilter = new RegExp(
        `${searchValue.toUpperCase()}.|${searchValue.toUpperCase()}`
      )
      let results = searchData.filter((topic) => {
        return topic.tags.some((tag) => searchFilter.test(tag))
      })
      setSearchResults(results)
    } else {
      setSearchResults([])
    }
  }, [searchData, searchValue])

  return (
    <div className='library-home-wrapper'>
      <div className={"library-home-search-box-container"}>
        <div className={"library-home-search-box-wrapper"}>
          {searchValue.length === 0 && (
            <FontAwesomeIcon icon={faSearch} id='searchIcon' />
          )}

          <input
            placeholder=' Search in Library'
            className={"library-home-search-box"}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            type='text'
          />
          {searchValue && (
            <span
              onClick={() => setSearchValue("")}
              className='library-home-search-clear'
              aria-hidden='true'
            >
              &times;
            </span>
          )}
        </div>
      </div>

      {searchValue.length ? (
        <div className='rectangle-listing'>
          {searchResults.map((result, index) => {
            return (
              <div
                key={`${result.catId}-${result.topicId}`}
                className={"library-home-search-listing"}
              >
                <RectangleLink
                  linkDest={`/library/category/${result.catId}/topic/${result.topicId}`}
                  styledOnMobile
                  colorText
                  color={result.color}
                  title={result.topicName}
                  flipped={flipIcon(1, index)}
                />
              </div>
            )
          })}
        </div>
      ) : null}
      {searchValue.length && !searchResults.length ? (
        <div className='library-home-no-results'>No results</div>
      ) : null}
      <p className='library-home-general-information'>
        General Information: The educational modules below provide summary
        information relevant to genetic testing for prostate cancer. For
        detailed information, please refer to Giri VN, Knudsen KE, Kelly WK, et
        al. Implementation of Germline Testing for Prostate Cancer: Philadelphia
        Prostate Cancer Consensus Conference 2019. J Clin Oncol. 2020 Aug
        20;38(24):2798-2811. doi: 10.1200/JCO.20.00046. Epub 2020 Jun 9. PMID:
        32516092; PMCID: PMC7430215. Additional references are available in the
        Reference List section.
      </p>
      {!searchValue.length &&
        LIBRARY_CONTENT.map((category, categoryIndex) => {
          return (
            <div key={category.name} className='library-home-cat-container'>
              <Link to={`/library/category/${category.sectionId}`}>
                <div
                  className={`library-home-cat-header library-home-${category.color}`}
                >
                  <div className='library-home-cat-header-text'>
                    {category.name}
                  </div>
                  <FontAwesomeIcon icon={faAngleRight} size={"lg"} />
                </div>
              </Link>
              <div className='library-home-topic-wrapper'>
                {category.topics.map((topic, topicIndex) => {
                  return (
                    <div
                      key={`${category.name}-${topic.name}`}
                      className='library-home-topic-container'
                    >
                      <RectangleLink
                        linkDest={`/library/category/${category.sectionId}/topic/${topic.topicId}`}
                        flipped={flipIcon(categoryIndex, topicIndex)}
                        title={topic.name}
                        color={category.color}
                        isLibraryHome
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Home
