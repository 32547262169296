import React from 'react'

function BannerExit({ onClick }) {
	return (
		<div className='banner-exit'>
			<h5 onClick={onClick}>{'<'} Exit Screening</h5>
		</div>
	)
}

export default BannerExit
