import React from "react"
import { useHistory } from "react-router-dom"
import "../components/Question/Question.scss"

import Banner from "../components/Banner/Banner"
import HelixButton from "../components/HelixButton/HelixButton"

export const Home = () => {
  const history = useHistory()

  return (
    <>
      <Banner color='aqua' hideDesktopWave colorfill flipped />
      <h1>Helix: Your Guide to Prostate Cancer Genetic Testing</h1>
      <p className='pre-buttons'>
        This toolkit gives information to healthcare providers regarding genetic
        testing for prostate cancer. The toolkit has two major components:
        Intake Questions and Educational Modules. The Intake Questions include a
        series of questions to help healthcare providers determine which men are
        eligible for prostate cancer genetic testing. The Educational Modules
        provide summary information regarding key topics and considerations
        regarding germline genetic testing for prostate cancer.
      </p>
      <div className='container'>
        <div className='row'>
          <HelixButton
            onClick={() => {
              history.push("/questions")
            }}
          >
            Intake Questions
          </HelixButton>
        </div>
        <div className='row'>
          <HelixButton
            onClick={() => {
              history.push("/library")
            }}
          >
            Educational Modules
          </HelixButton>
        </div>
      </div>
    </>
  )
}
