import React from 'react'
import PropTypes from 'prop-types'
import './Question.scss'
import Banner from '../Banner/Banner'
import BannerClose from '../Banner/BannerClose/BannerClose'
import BannerExit from '../Banner/BannerExit/BannerExit'

export const Question = ({ header, component: Component, onExit, ...rest }) => (
	<>
		<Banner color='aqua' hideDesktopWave flipped>
			<div className='hide-desktop'>
				<BannerClose onClick={onExit} />
			</div>
			<div className='hide-mobile'>
				<BannerExit onClick={onExit} />
			</div>
		</Banner>
		<h1>{header}</h1>
		<div className='container'>
			<div className='button-wrapper'>
				<Component {...rest} />
			</div>
		</div>
	</>
)

Question.propTypes = {
	header: PropTypes.string.isRequired,
	component: PropTypes.func.isRequired,
	onExit: PropTypes.func.isRequired
}
