import React from "react"
import { Link } from "react-router-dom"

import WaveIcon from "../WaveIcon/WaveIcon"

import "./RectangleLink.scss"

function RectangleLink({
  color,
  clip,
  flipped,
  linkDest,
  title,
  mdup,
  styledOnMobile,
  colorText,
  isLibraryHome,
}) {
  return (
    <Link
      className={`rectangle-link ${color ? color : `aqua`} ${
        flipped ? "flipped" : ""
      } ${styledOnMobile ? "preserve-on-mobile" : ""} ${
        colorText ? `container-${color}` : ""
      }`}
      to={linkDest}
    >
      <WaveIcon color={color} flipped={flipped} mdup={mdup} />
      <div className={isLibraryHome ? "library-home-topic-title" : ""}>
        {title}
      </div>
    </Link>
  )
}

export default RectangleLink
