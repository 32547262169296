import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

import Navbar from '../Navbar/Navbar'

import { ReactComponent as HeaderIcon } from '../../../assets/svg/helix-type.svg'
import { ReactComponent as NavToggleClosedIcon } from '../../../assets/svg/more-icon.svg'
import { ReactComponent as NavToggleOpenIcon } from '../../../assets/svg/x-icon.svg'

import './Header.scss'

const Header = () => {
	const history = useHistory()
	const [navbarToggleState, setNavbarToggleState] = useState(false)

	const toggleNavbarState = () => {
		setNavbarToggleState(!navbarToggleState)
	}

	const closeNavBarState = () => {
		setNavbarToggleState(false)
	}

	return (
		<div className={`header ${navbarToggleState ? 'fixed' : ''}`}>
			<header className={`${navbarToggleState ? 'fixed' : ''}`}>
				{navbarToggleState ? (
					<NavToggleOpenIcon
						className='nav-toggle'
						onClick={toggleNavbarState}
					/>
				) : (
					<NavToggleClosedIcon
						className='nav-toggle'
						onClick={toggleNavbarState}
					/>
				)}
				<div className='helix'>
					<Link to='/'>
						<HeaderIcon
							onClick={() => {
								closeNavBarState()
								history.push('/')
							}}
						/>
					</Link>
				</div>
			</header>
			<Navbar close={closeNavBarState} state={navbarToggleState} />
		</div>
	)
}

export default Header
