import React from "react";

function SearchListing() {
	return (
		<div>
			<h1>This is SearchListing</h1>
		</div>
	);
}

export default SearchListing;
