import React from 'react'

import { ReactComponent as BackIcon } from '../../../assets/svg/back-icon_circled.svg'

function BannerBack({ onClick }) {
	return (
		<div className='banner-back'>
			<BackIcon onClick={onClick} />
		</div>
	)
}

export default BannerBack
