import React from "react";
import "./NavigationLibraryBottom.scss"
import {useParams, Link} from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/svg/back-icon_circled_black.svg'
import { ReactComponent as ForwardIcon } from '../../assets/svg/forward-icon_circled_black.svg'
import LIBRARY_CONTENT from "../../pages/library/LIBRARY_CONTENT";



function NavigationLibraryBottom({pagination, length }) {
	const {section, subSection} = useParams();
	const category = LIBRARY_CONTENT.find(({sectionId})=>section===sectionId)
	const topic = category.topics.find(({topicId})=>subSection===topicId)

	return (
		<div
		 onClick={() => window.scrollTo(0,0)}
		 className='navigation-library-bottom-wrapper'
		 >
			<div className={`wrapper-back-${category.color}`}>
			{pagination > 1 ?
			 <Link to={`/library/category/${category.sectionId}/topic/${parseInt(topic.topicId) - 1}`}>
			 	<BackIcon  />
			 </Link>
			  : "" }
			</div>
			

			<div className='pagination'>
				{pagination} of {length}
			</div>

			<div onClick={() => window.scrollTo(0,0)} className={`wrapper-back-${category.color}`} >	
				{pagination < length ?
				<Link to={`/library/category/${category.sectionId}/topic/${parseInt(topic.topicId) + 1}`}>
				 	<ForwardIcon />
			 	</Link>
				: "" }
			</div>


		</div>
	) 
}



export default NavigationLibraryBottom;