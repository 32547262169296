import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Question } from '../../components/Question'
import QUESTIONS from './ALL_QUESTIONS'

export const Questions = () => {
	const [currentQuestion, setCurrentQuestion] = useState(QUESTIONS[1])
	const history = useHistory()

	const exitScreening = useCallback(() => {
		history.push('/')
	}, [history])

	const answerQuestion = useCallback(
		nextQuestion => {
			if (nextQuestion.includes('exit-')) {
				// TODO: More logic around which question and which docs
				history.push(`/results/${nextQuestion}`)
			}

			if (nextQuestion === 'end') {
				history.push(`/results/end`)
			}

			setCurrentQuestion(QUESTIONS[nextQuestion])
		},
		[setCurrentQuestion, history]
	)

	return (
		<>
			<Question
				{...currentQuestion}
				onExit={exitScreening}
				answerQuestion={answerQuestion}
				component={currentQuestion.component}
			/>
		</>
	)
}
