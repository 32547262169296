import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as HomeIcon } from '../../../assets/svg/home-icon.svg'
import { ReactComponent as QuestionsIcon } from '../../../assets/svg/questions-icon.svg'
import { ReactComponent as LibraryIcon } from '../../../assets/svg/library-icon.svg'
import { ReactComponent as AboutIcon } from '../../../assets/svg/about.svg'
import { ReactComponent as ReferenceIcon } from '../../../assets/svg/reference.svg'


import './Navbar.scss'

const Navbar = ({ close, state }) => {
	const location = useLocation()
	return (
		<nav className={state ? 'open' : 'closed'}>
			<ul>
				<li
					className={`nav-item ${
						location.pathname === '/' ? 'active' : ''
					}`}
				>
					<Link to='/' onClick={close} >
						<HomeIcon />
						<div>	
							<span>Home </span> <span> Page </span>
						</div>
					</Link>
				</li>

				<li
					className={`nav-item ${
						location.pathname.startsWith('/about') ? 'active' : ''
					}`}
				>
					<Link to='/about' onClick={close}>
						<AboutIcon />
						<div>
							<span> About This </span> <span> App </span>
						</div>
					</Link>
				</li>

				<li
					className={`nav-item ${
						location.pathname.startsWith('/references') ? 'active' : ''
					}`}
				>
					<Link to='/references' onClick={close}>
						<ReferenceIcon />
						<div>
							<span> Reference </span> <span> List </span>
						</div>
					</Link>
				</li>

				<li
					className={`nav-item ${
						location.pathname.startsWith('/questions') ||
						location.pathname.startsWith('/results')
							? 'active'
							: ''
					}`}
				>
					<Link to='/questions' onClick={close}>
						<QuestionsIcon />
						<div>
							<span> Intake </span> <span> Questions </span>
						</div>
					</Link>
				</li>
				<li
					className={`nav-item ${
						location.pathname.startsWith('/library') ? 'active' : ''
					}`}
				>
					<Link to='/library' onClick={close}>
						<LibraryIcon />
						<div>
							<span> Educational </span> <span> Modules</span>
						</div>
					</Link>
				</li>
				

			</ul>
		</nav>
	)
}

export default Navbar
