import React from "react";

import { ReactComponent as FooterIcon } from "../../../assets/svg/helix-icon.svg";

import "./Footer.scss";

const Footer = () => (
	<footer>
		<p id="leftFooter">Copyright © 2022 Thomas Jefferson University Hospitals. All Rights Reserved.</p>
		<FooterIcon />
		<div id="rightFooter"></div>
	</footer>
);

export default Footer;
