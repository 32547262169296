import React from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/svg/close-icon_circled.svg'

function BannerClose({ onClick }) {
	return (
		<div className='banner-close'>
			<CloseIcon onClick={onClick} />
		</div>
	)
}

export default BannerClose
