import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import HelixButton from '../HelixButton/HelixButton'
import { cloneDeep } from 'lodash'

const AGE_OPTIONS = [
	{ text: 'Father', age: '', isActive: null, isSelected: null },
	{ text: 'Brother(s)', age: '', isActive: null, isSelected: null },
	{ text: 'Grandfather(s)', age: '', isActive: null, isSelected: null },
	{ text: 'Uncle(s)', age: '', isActive: null, isSelected: null },
	{ text: 'Cousin(s)', age: '', isActive: null, isSelected: null }
]

const OTHER_OPTIONS = [
	{ text: 'None', isSelected: null },
	{ text: "Don't know", isSelected: null }
]

export const RelativeQuestion = ({ nextQuestion, answerQuestion }) => {
  const [ageOptions, setAgeOptions] = useState(cloneDeep(AGE_OPTIONS))
  const [otherOptions, setOtherOptions] = useState(cloneDeep(OTHER_OPTIONS))
  const [selectedAges, setSelectedAges] = useState([])
  
  const toggleAgeSelected = (index) => {
    const options = ageOptions.map((option, i) => {
      if (i === index) {
        option.isActive = true
        option.isSelected = true
      }
      return option
    })
    setAgeOptions(options)
  }

  const closeAgeOption = (index) => {
    
    const options = ageOptions.map((option, i) => {
      if (i === index) {
        if (option.isActive) {
          option.isActive = false
          if (isNaN(parseInt(option.age))) option.isSelected = false
        } else if (option.isSelected){
          option.isSelected = false
          option.age = ''
        }
      }
      return option
    })
    setAgeOptions(options)
  }

  const closeOtherOption = (index) => {
    
    const options = otherOptions.map((option, i) => {
      if (i === index) {
          option.isSelected = false
      }
      return option
    })
    setOtherOptions(options)
  }

  const handleAgeChange = (value, index) => {
    const options = ageOptions.map((option, i) => {
      if (i === index) {
        option.age = value
      }
      return option
    })
    setAgeOptions(options)
  }

  const toggleOtherSelected = (index) => {
    const options = otherOptions.map((option, i) => {
      if (i === index) {
        option.isSelected = !option.isSelected
      }
      return option
    })
    setOtherOptions(options)
  }

  const isDisabled = () => {
    let flag = false

    if (selectedAges.length) { // Check all selected options have age
      for (let i = 0; i < selectedAges.length; i++) {
        const option = selectedAges[i]
        if (isNaN(parseInt(option.age))) flag = true // Ensure a number is set
        if (flag) return true
      }
    }

    const othersSelected = otherOptions.filter(option => option.isSelected)
    return selectedAges.length === 0 && othersSelected.length === 0
  }

  const onSubmit = () => {

    if (selectedAges.length) { // Check all selected option have age
      for (let i = 0; i < selectedAges.length; i++) {
        const option = selectedAges[i]
        if (option.age < 60) return answerQuestion('exit-5')
      }
    }
    
    return answerQuestion(nextQuestion)
  }

  // Set selected age options whenever ageOptions changes
  useEffect(() => {
    setSelectedAges(ageOptions.filter(option => option.isSelected))
  }, [ageOptions])



  return (
    <>
        {ageOptions.map((option,i) => (
          <div className={`${option.isSelected ? 'button-active' : 'button-inactive'}`} key={`age ${i}`}>
            <Button 
              isActive={option.isActive}
              isSelected={option.isSelected} 
              onClose={() => closeAgeOption(i)}
              onClick={() => toggleAgeSelected(i)}
            >
              {option.text}
            </Button>
            {option.isActive && (
              <div className="row rq-input-container">
                <p>At what age?</p>
                <input
                  type="number"
                  value={option.age}
                  className="button-input"
                  placeholder="Enter Response"
                  onChange={e => handleAgeChange(e.target.value, i)}
                />
              </div>
            )}
          </div>
        ))}
        {otherOptions.map((option,i) => (
          <div 
            key={`other ${i}`}
            className={`${option.isSelected ? 'button-active' : 'button-inactive'}`}
          >
            <Button 
              onClick={() => toggleOtherSelected(i)}
              onClose={() => closeOtherOption(i)}
              isSelected={option.isSelected}
            >
              {option.text}
            </Button>
          </div>

        ))}

      
      <div className='submit-wrapper-button'>
        {isDisabled() === false ? <HelixButton
          disabled={isDisabled()}
          onClick={onSubmit}
        >
          Submit 
        </HelixButton> : "" }
      </div>

    </>
  )
}

RelativeQuestion.propTypes = {
	nextQuestion: PropTypes.string.isRequired,
	answerQuestion: PropTypes.func.isRequired
}
