import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as MobileWave } from '../../assets/svg/wave-full.svg'
import { ReactComponent as DesktopWave } from '../../assets/svg/DesktopWave.svg'

import './Banner.scss'

function Banner({ flipped, color, colorfill, children, hideDesktopWave, noPadding, noMargin }) {
	return (
		<div
			className={`banner ${color} ${
				colorfill && 'colorfill'
			} ${hideDesktopWave && 'hidden-desktop'} ${noPadding && 'no-padding'} ${noMargin && 'no-margin'}`}
		>
			<div className={`image ${hideDesktopWave && 'hide-desktop'}`}>
				<MobileWave className={`mobile ${flipped && 'flipped'}`} />
				<DesktopWave
					className={`desktop ${flipped && 'flipped'}`}
				/>
			</div>
			<div className='overlapping-container'>{children}</div>
		</div>
	)
}

Banner.propTypes = {
	color: PropTypes.string,
	flipped: PropTypes.bool,
	colorfill: PropTypes.bool
}

export default Banner
