import React from 'react'
import HelixButton from "../HelixButton/HelixButton"
import PropTypes from 'prop-types'

export const PatientQuestion = ({ options, answerQuestion }) => (
  <div className="row">
    {options.map((option,i) => (
      <HelixButton key={i} onClick={() => answerQuestion(option.path)}>
        {option.text}
      </HelixButton>
    ))}

</div>
)

PatientQuestion.propTypes = {
  options: PropTypes.array,
  answerQuestion: PropTypes.func
}