import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from "../Button/Button"
import HelixButton from "../HelixButton/HelixButton"
import { cloneDeep } from 'lodash'

const OTHER_OPTIONS = [
	{ text: 'None', isSelected: null },
	{ text: "Don't know", isSelected: null }
]

export const HistoryQuestion = ({ resultPage, options, nextQuestion, answerQuestion, addDocCount }) => {
  const [passedOptions, setPassedOptions] = useState(cloneDeep(options))
  const [otherOptions, setOtherOptions] = useState(cloneDeep(OTHER_OPTIONS))
  const [selectedPassedOptions, setSelectedPassedOptions] = useState([])

  const toggleCancerSelected = index => {
    const options =  passedOptions.map((opt, i) => {
      if (index === i) {
        opt.isSelected = !opt.isSelected
      }
      return opt
    })
    setPassedOptions(options)
  }

  const toggleOtherSelected = index => {
    const options =  otherOptions.map((opt, i) => {
      if (index === i) {
        opt.isSelected = !opt.isSelected
      }
      return opt
    })
    setOtherOptions(options)
  }

  const closePassedOption = (index) => {
    
    const options = passedOptions.map((option, i) => {
      if (i === index) {
          option.isSelected = false
      }
      return option
    })
    setPassedOptions(options)
  }

  const closeOtherOption = (index) => {
    
    const options = otherOptions.map((option, i) => {
      if (i === index) {
          option.isSelected = false
      }
      return option
    })
    setOtherOptions(options)
  }

	const isDisabled = () => {
		const othersSelected = otherOptions.filter(option => option.isSelected)
		return selectedPassedOptions.length === 0 && othersSelected.length === 0
	}

	const onSubmit = () => {
		// Check if selected amount is over the requried amount
		if (selectedPassedOptions.length > addDocCount) {
			return answerQuestion(`exit-${resultPage}`)
		}

		if (selectedPassedOptions.length) {
			for (let i = 0; i < selectedPassedOptions.length; i++) {
				const option = selectedPassedOptions[i]
				if (option.additonalDoc) return answerQuestion(`exit-${resultPage}`)
			}
		}

		answerQuestion(nextQuestion)
	}

  useEffect(()=>{
    setOtherOptions(cloneDeep(OTHER_OPTIONS))
    setPassedOptions(cloneDeep(options))
  },[options])

  useEffect(() => {
    setSelectedPassedOptions(passedOptions.filter(option => option.isSelected))
  }, [passedOptions])


  return (
    <>
        {passedOptions.map((option,i) => (
          <div key={`cancer ${i}`}>
            <Button  
              onClick={() => toggleCancerSelected(i)}
              isSelected={option.isSelected}
              onClose={()=>closePassedOption(i)}
            >
              {option.text}
            </Button>
          </div>

        ))}
        {otherOptions.map((option,i) => (
          <div key={`other ${i}`}>
            <Button 
              onClick={() => toggleOtherSelected(i)}
              onClose={()=>closeOtherOption(i)}
              isSelected={option.isSelected}
            >
              {option.text}
            </Button>
          </div>
        ))}
      <div className="submit-wrapper-button">
        {isDisabled() === false ? <HelixButton
          disabled={isDisabled()}
          onClick={onSubmit}
        >
          Submit 
        </HelixButton> : "" }
      </div>
    </>
  )
}

HistoryQuestion.propTypes = {
	nextQuestion: PropTypes.string.isRequired,
	answerQuestion: PropTypes.func.isRequired,
	addDocCount: PropTypes.number.isRequired
}
