import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Home } from './home'
import { Library } from './library/index'
import { Questions } from './questions'
import { Results } from './results'
import { References } from './references/references'
import { About } from './about/about'
import { StyleGuide } from './styleGuide'

const routeDefinitions = [
	{ component: Home, exact: true, path: '/' },
	{ component: Library, path: '/library' },
	{ component: Questions, path: '/questions' },
	{ component: Results, path: '/results' },
	{ component: References, path: '/references' },
	{ component: About, path: '/about' },
	{ component: StyleGuide, path: '/styleguide' }
]

export const Routing = () => (
	<Switch>
		{routeDefinitions.map(({ ...rest }) => (
			<Route {...rest} key={rest.path} />
		))}
		{/* TODO: MAKE 404 OR KEEP REDIRECT TO HOME */}
		<Redirect from='*' to='/' />
	</Switch>
)
