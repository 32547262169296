import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import ArticleList from '../ArticleList/ArticleList'
import ArticleNav from '../ArticleNav/ArticleNav'
import ArticleSlide from '../ArticleSlide/ArticleSlide'
import ArticleSlider from '../ArticleSlider/ArticleSlider'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import Button from '../Button/Button'
import CategoryHeading from '../CategoryHeading/CategoryHeading'
import CategoryListing from '../CategoryListing/CategoryListing'
import CategorySection from '../CategorySection/CategorySection'
import HelixButton from '../HelixButton/HelixButton'
import RectangleLink from '../RectangleLink/RectangleLink'
import SearchBar from '../SearchBar/SearchBar'
import SearchListing from '../SearchListing/SearchListing'
import SelectFill from '../SelectFill/SelectFill'

function StyleTemplate() {
	return (
		<Fragment>
			<h1>This is a header.</h1>
			<p>
				This is text <Link href='#'>with a link</Link>
			</p>
			<section>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Amet doloribus earum molestias sint sit, vitae voluptatum.
					Aut corporis cumque dolore eius eum harum, labore, nulla
					porro quo saepe unde voluptate!
				</p>
				<ul>
					<li>
						Intake of family cancer history, medical history, and
						prior genetic testing in the family.
					</li>
					<li>
						Discuss multitple aspects of genetic testing (See figure
						to the right) with their patients for patients to make
						an informed decision regarding genetic testing
					</li>
					<li>
						Coordinate genetic testing for patients and coordinate
						genetic testing of relatives
					</li>
				</ul>
				<p>
					<b>This is bold text</b>
				</p>
			</section>
			<div className='container-red'>
				<h1>This is a red container</h1>
				<p>
					This is text <Link href='#'>with a link</Link>
				</p>
			</div>
			<div className='container-blue'>
				<h1>This is a blue container</h1>
				<p>
					This is text <Link href='#'>with a link</Link>
				</p>
			</div>
			<div className='container-green'>
				<h1>This is a green container</h1>
				<p>
					This is text <Link href='#'>with a link</Link>
				</p>
			</div>
			<div className='container-purple'>
				<h1>This is a purple container</h1>
				<p>
					This is text <Link href='#'>with a link</Link>
				</p>
			</div>
			<div className='container-orange'>
				<h1>This is a orange container</h1>
				<p>
					This is text <Link href='#'>with a link</Link>
				</p>
			</div>
			<hr />
			<h1>Individual Components</h1>
			<ArticleList />
			<ArticleNav />
			<ArticleSlide />
			<ArticleSlider />
			<AudioPlayer />
			<Button />
			<CategoryHeading />
			<CategoryListing />
			<CategorySection />
			<HelixButton />
			<div className='rectangle-listing'>
				<RectangleLink
					linkDest={`/styleguide`}
					title='Aqua RectangleLink'
				/>
				<RectangleLink
					linkDest={`/styleguide`}
					title='Aqua RectangleLink Flipped'
					flipped
				/>
				<RectangleLink
					color='blue'
					linkDest={`/styleguide`}
					title='Blue RectangleLink'
				/>
				<RectangleLink
					color='blue'
					linkDest={`/styleguide`}
					title='Blue RectangleLink Flipped'
					flipped
				/>
				<RectangleLink
					color='red'
					linkDest={`/styleguide`}
					title='Red RectangleLink'
				/>
				<RectangleLink
					color='red'
					linkDest={`/styleguide`}
					title='Red RectangleLink Flipped'
					flipped
				/>
				<RectangleLink
					color='green'
					linkDest={`/styleguide`}
					title='Green RectangleLink'
				/>
				<RectangleLink
					color='green'
					linkDest={`/styleguide`}
					title='Green RectangleLink Flipped'
					flipped
				/>
				<RectangleLink
					color='orange'
					linkDest={`/styleguide`}
					title='Orange RectangleLink'
				/>
				<RectangleLink
					color='orange'
					linkDest={`/styleguide`}
					title='Orange RectangleLink Flipped'
					flipped
				/>
				<RectangleLink
					color='purple'
					linkDest={`/styleguide`}
					title='Purple RectangleLink'
				/>
				<RectangleLink
					color='purple'
					linkDest={`/styleguide`}
					title='Purple RectangleLink Flipped'
					flipped
				/>
			</div>
			<SearchBar />
			<SearchListing />
			<SelectFill />
		</Fragment>
	)
}

export default StyleTemplate
