import React from "react"

export default [
  {
    name: "Prostate Cancer Inheritance",
    color: "blue",
    sectionId: "1",
    topics: [
      {
        name: "Inherited Risk",
        topicId: "1",
        audio: "../../assets/audio/S1P1_inherited-risk.mp3",
        paragraphs: [
          <>
            Most men with prostate cancer do not have hereditary prostate cancer
            and their prostate cancer is sporadic (due to genetic changes
            acquired in a person’s lifetime).
          </>,
          <>
            Approximately 5-15% of prostate cancer is due to inherited genetic
            mutations. A genetic mutation passed from either mother or father to
            a child is known as a “germline mutation” and can lead to inherited
            risk for cancer.
          </>,
          <>
            Having a genetic mutation does not automatically mean you will
            develop cancer, but rather that the risk for cancer is higher.
          </>,
          <>
            Men with metastatic prostate cancer have the highest rates of
            genetic mutations close to 15%.
          </>,
          <>
            Men with early stage prostate cancer can have inherited genetic
            mutations particularly if they have a family history of cancers such
            as prostate, breast, ovarian, pancreatic, colorectal, and uterine
            cancers.
          </>,
        ],
        tags: [
          "inherited",
          "risk",
          "genetic",
          "mutation",
          "germline",
          "prostate",
          "cancer",
          "breast",
          "ovarian",
          "pancreatic",
          "colorectal",
          "uterine",
        ],
      },
      {
        name: "Family History",
        topicId: "2",
        audio: "",
        paragraphs: [
          <>Family history can indicate inherited risk for cancer.</>,
          <>
            It is important to think about the family history of cancer in males
            and females on mother’s side and father’s side of the family.
          </>,
          <>
            In addition, information on the following is very helpful when
            thinking about risk for cancer:
            <ul>
              <li>
                Cancers in three generations (including grandparents, parents,
                aunts, uncles, cousins, siblings, children, nieces, nephews)
              </li>
              <li>
                Types of cancer in men and women, particularly breast, ovarian,
                prostate, pancreatic, colorectal, andine cancer
              </li>
              <li>
                Features of prostate cancer (Gleason score, stage, other
                pathologic features)
              </li>
              <li>Age of diagnosis of cancers in family members</li>
              <li>
                Screening or treatment information for patients and family
                members
              </li>
              <li>Age of death from cancer</li>
              <li>
                Lifestyle factors in the family (smoking, alcohol history,
                exposure history, etc)
              </li>
              <li>Ancestry</li>
              <li>
                Genetic test results if testing was performed by a family member
              </li>
            </ul>
          </>,
          <>
            Family history can guide choice of genes for genetic testing and
            further cancer screening recommendations.
          </>,
        ],
        tags: [
          "family",
          "history",
          "cancer",
          "breast",
          "ovarian",
          "prostate",
          "pancreatic",
          "colorectal",
          "uterine",
          "Gleason",
          "genetic",
          "testing",
          "screening",
          "ancestry",
          "smoke",
          "smoking",
          "alcohol",
        ],
      },
      {
        name: "Genetic Predisposition",
        topicId: "3",
        audio: "",
        paragraphs: [
          <>
            Mutations have been identified in a spectrum of genes in men with
            prostate cancer with varying degrees of risk for prostate cancer or
            aggressive prostate cancer.
          </>,
          <>
            Rates of mutations have been most commonly identified in{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2 (4.5%)
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              CHEK2 (2.2%)
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              ATM (1.8%)
            </a>
            , and{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA1 (1.1%)
            </a>
            .
          </>,
          <>
            The risk for prostate cancer is highest for{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2 (up to 8 times the risk)
            </a>
            ,{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              HOXB13 (up to 8 times the risk),
            </a>{" "}
            and{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA1 (up to 3 times the risk)
            </a>
            .
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2
            </a>{" "}
            mutations are associated with aggressive prostate cancer with poor
            outcomes (up to 4 times the risk for aggressive disease and 5-fold
            increased risk for prostate cancer-related death).
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA1
            </a>{" "}
            mutations are associated with ~2-fold increased risk for prostate
            cancer.
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              HOXB13
            </a>{" "}
            mutations have been associated with ~8 to 9-fold increased risk for
            prostate cancer and early-onset prostate cancer. No association with
            aggressive disease.
          </>,
          <>
            A specific mutation in{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              ATM (P1054R)
            </a>{" "}
            has been associated with a ~2-fold increased risk for prostate
            cancer. ATM may predict fatal prostate cancer though this is early
            data.
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              NBN
            </a>{" "}
            mutations have been associated with ~2-fold prostate cancer risk in
            Polish population.
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              CHEK2 1100delC
            </a>{" "}
            is associated with ~3-fold risk for prostate cancer.
          </>,
          <>
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              CHEK2 I157T
            </a>{" "}
            is associated with ~1.8-fold risk for prostate cancer.
          </>,
        ],
        tags: [
          "genetic",
          "predisposition",
          "gene",
          "mutations",
          "cancer",
          "prostate",
          "aggressive",
          "BRCA2",
          "CHEK2",
          "1100delC",
          "I157T",
          "ATM",
          "BRCA1",
          "HOXB13",
          "risk",
          "P1054R",
          "NBN",
        ],
      },
      {
        name: "Lifetime Risk",
        topicId: "4",
        audio: "",
        paragraphs: [
          <>
            General population risk for prostate cancer is approximately 12%.
          </>,
          <>Lifetime risk for prostate cancer can vary by study.</>,
          <>
            Lifetime risks reported by genes is below:{" "}
            <ul>
              <li>
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA1 and BRCA2:
                </a>{" "}
                up to 20-60%
              </li>
              <li>
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  DNA mismatch repair genes (MLH1, MSH2, MSH6, PMS2, EPCAM):
                </a>{" "}
                up to 30%
              </li>
              <li>
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  HOXB13:
                </a>{" "}
                30-60%
              </li>
            </ul>
          </>,
          <>
            Estimates of lifetime prostate cancer risk for other genes on
            prostate cancer gene panels are not currently well-established.
          </>,
        ],
        tags: [
          "lifetime",
          "risk",
          "cancer",
          "prostate",
          "BRCA1",
          "BRCA2",
          "DNA",
          "mismatch",
          "repair",
          "gene",
          "panel",
          "MLH1",
          "MSH2",
          "MSH6",
          "PMS2",
          "EPCAM",
          "HOXB13",
        ],
      },
    ],
  },
  {
    name: "Genetic Testing Overview",
    color: "red",
    sectionId: "2",
    topics: [
      {
        name: "Associated Cancer Risks by Gene Type",
        topicId: "1",
        audio: "",
        paragraphs: [
          <>
            Multigene panels may uncover many cancer risks beyond the cancer of
            concern.
          </>,
          <>
            For example,{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2
            </a>{" "}
            mutations are associated with risk for multiple cancers in men and
            women:{" "}
            <ul>
              <li>
                Men: risks for prostate cancer, pancreatic cancer, male breast
                cancer, and melanoma
              </li>
              <li>
                Women: risks for breast cancer, ovarian cancer, pancreatic
                cancer, and melanoma
              </li>
            </ul>
          </>,
          <>
            These cancer risks can have major management recommendations and
            impact males and females in families.
          </>,
        ],
        tags: [
          "detect",
          "cancer",
          "risk",
          "multigene",
          "panel",
          "prostate",
          "pancreatic",
          "breast",
          "melanoma",
          "ovarian",
          "gene",
          "associate",
          "type",
          "BRCA1",
          "BRCA2",
          "MLH1",
          "MSH2",
          "PMS2",
          "EPCAM",
          "APC",
          "MUTYH",
          "TP53",
          "PTEN",
          "CDH1",
          "BMPR1A",
          "SMAD4",
          "STK11",
          "CDKN2A",
          "CDK4",
          "ATM",
          "CHEK2",
          "PALB2",
          "BARD1",
          "BRIP1",
          "NBN",
          "RAD51C",
          "RAD51D",
          "POLD1",
          "POLE",
          "GREM1",
        ],
        subTitle: "List of Associated Cancer Risks by Gene Type",
        accordions: [
          {
            title: "BRCA1 Gene",
            text: [
              "Syndrome: HBOC (Hereditary Breast and Ovarian Cancer Syndrome) Associated",
              "Cancer Risks: Prostate, Breast, Ovarian, Pancreatic",
            ],
          },
          {
            title: "BRCA2 Gene",
            text: [
              "Syndrome: HBOC (Hereditary Breast and Ovarian Cancer Syndrome)",
              "Associated Cancer Risks: Prostate, Breast, Ovarian, Melanoma, Pancreatic",
            ],
          },
          {
            title: "MLH1 Gene",
            text: [
              "Syndrome: LS (Lynch Syndrome)",
              "Associated Cancer Risks: Prostate, Ovarian, Colorectal, Endometrial, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "MSH2 Gene",
            text: [
              "Syndrome: LS (Lynch Syndrome)",
              "Associated Cancer Risks: Prostate, Ovarian, Colorectal, Endometrial, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "MSH6 Gene",
            text: [
              "Syndrome: LS (Lynch Syndrome)",
              "Associated Cancer Risks: Prostate, Ovarian, Colorectal, Endometrial, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "PMS2 Gene",
            text: [
              "Syndrome: LS (Lynch Syndrome)",
              "Associated Cancer Risks: Ovarian, Colorectal, Endometrial, Pancreatic, Gastric",
            ],
          },
          {
            title: "EPCAM Gene",
            text: [
              "Syndrome: LS (Lynch Syndrome)",
              "Associated Cancer Risks: Ovarian, Colorectal, Endometrial, Pancreatic, Gastric",
            ],
          },
          {
            title: "APC Gene",
            text: [
              "Syndrome: FAP (Familial Adenomatous Polyposis)",
              "Associated Cancer Risks: Colorectal, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "MUTYH Gene",
            text: [
              "Syndrome: MAP (MUTYH-associated Polyposis)",
              "Associated Cancer Risks: Colorectal",
            ],
          },
          {
            title: "TP53 Gene",
            text: [
              "Syndrome: LFS (Li-Fraumeni Syndrome)",
              "Associated Cancer Risks: Prostate, Breast, Ovarian, Colorectal, Endometrial, Melanoma, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "PTEN Gene",
            text: [
              "Syndrome: Cowden Syndrome",
              "Associated Cancer Risks: Breast, Colorectal, Endometrial, Others",
            ],
          },
          {
            title: "CDH1 Gene",
            text: [
              "Syndrome: HDGC (Hereditary Diffuse Gastric Cancer)",
              "Associated Cancer Risks: Breast, Colorectal, Gastric",
            ],
          },
          {
            title: "BMPR1A Gene",
            text: [
              "Syndrome: JPS (Juvenile Polyposis Syndrome)",
              "Associated Cancer Risks: Colorectal, Gastric",
            ],
          },
          {
            title: "SMAD4 Gene",
            text: [
              "Syndrome: JPS (Juvenile Polyposis Syndrome)",
              "Associated Cancer Risks: Colorectal, Pancreatic, Gastric",
            ],
          },
          {
            title: "HOXB13",
            text: [
              "Syndrome: Hereditary Prostate Cancer",
              "Associated cancer risks: prostate cancer, especially early-onset prostate cancer",
            ],
          },
          {
            title: "STK11 Gene",
            text: [
              "Associated Cancer Risks: Breast, Ovarian, Colorectal, Endometrial, Pancreatic, Gastric, Others",
            ],
          },
          {
            title: "CDKN2A Gene",
            text: ["Associated Cancer Risks: Melanoma, Pancreatic"],
          },
          {
            title: "CDK4 Gene",
            text: ["Associated Cancer Risks: Melanoma, Pancreatic"],
          },
          {
            title: "ATM Gene",
            text: ["Associated Cancer Risks: Breast, Pancreatic"],
          },
          {
            title: "CHEK2 Gene",
            text: ["Associated Cancer Risks: Prostate, Breast, Colorectal"],
          },
          {
            title: "PALB2 Gene",
            text: ["Associated Cancer Risks: Breast, Pancreatic"],
          },
          {
            title: "BARD1 Gene",
            text: ["Associated Cancer Risks: Breast"],
          },
          {
            title: "BRIP1 Gene",
            text: ["Associated Cancer Risks: Breast, Ovarian"],
          },
          {
            title: "NBN Gene",
            text: ["Associated Cancer Risks: Prostate, Breast"],
          },
          {
            title: "RAD51C Gene",
            text: ["Associated Cancer Risks: Breast, Ovarian"],
          },
          {
            title: "RAD51D Gene",
            text: ["Associated Cancer Risks: Ovarian"],
          },
          {
            title: "POLD1 Gene",
            text: ["Associated Cancer Risks: Colorectal"],
          },
          {
            title: "POLE Gene",
            text: ["Associated Cancer Risks: Colorectal"],
          },
          {
            title: "GREM1 Gene",
            text: ["Associated Cancer Risks: Colorectal"],
          },
        ],
      },
      {
        name: "Genetic Counseling",
        topicId: "2",
        audio: "",
        paragraphs: [
          <>
            Genetic counselors are masters-level healthcare professionals
            specifically trained in genetic assessment for various diseases such
            as cancer inheritance. They perform the following in their practice:
            <ul className='no-pad-bottom'>
              <li>
                Intake of family cancer history, medical history, and prior
                genetic testing in the family
              </li>
              <li>
                Discuss multiple aspects of genetic testing (See figure to the
                right) with their patients for patients to make an informed
                decision regarding genetic testing
              </li>
              <li>
                Coordinate genetic testing for patients and coordinate genetic
                testing of relatives
              </li>
            </ul>
          </>,
          <>
            If you choose to pursue genetic testing in your practice, your
            responsibilities include providing patients with these
            considerations of genetic testing as shown to the right prior to
            testing for men to make an informed decision regarding genetic
            testing.
          </>,
          <>
            For more information on how to find a genetic counselor in your
            area, please go to:{" "}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.nsgc.org'
            >
              {" "}
              nsgc.org
            </a>
            .
          </>,
        ],
        tags: [
          "genetic",
          "counseling",
          "assessment",
          "disease",
          "cancer",
          "inheritance",
          "intake",
          "family",
          "history",
          "testing",
        ],
      },
      {
        name: "Genetic Testing",
        topicId: "3",
        audio: "",
        paragraphs: [
          <>
            Genetic testing involves assessing genes or genetic regions for
            mistakes that may have been inherited and that lead to increased
            risk for cancer.
          </>,
          <>
            A gene is the basic unit of heredity. Genes control the functions of
            our cells.
          </>,
          <>
            Genetic testing is performed to identify genetic mutations —
            mistakes in genes that lead to diseases such as cancer.
          </>,
          <>Genetic testing can be performed on a blood or saliva sample.</>,
          <>
            The sample is sent to a qualified clinical genetic testing
            laboratory to perform the genetic testing.
          </>,
          <>
            Quality of the lab matters: Labs should have sequencing,
            rearrangement testing, deletion/duplication testing, testing of
            difficult regions (such as pseudogene regions), and plans for
            variant reclassification.
          </>,
          <>
            Data sharing policies also need to be clearly defined by the labs
            utilized for testing.
          </>,
        ],
        tags: [
          "genetic",
          "testing",
          "assess",
          "gene",
          "inherited",
          "risk",
          "cancer",
          "heredity",
          "mutations",
          "disease",
          "sequencing",
          "rearrangement",
          "deletion",
          "duplication",
          "region",
          "pseudogene",
          "variant",
          "reclassification",
          "data",
          "sharing",
        ],
      },
      {
        name: "Genetic vs. Genomic Testing",
        topicId: "4",
        audio: "",
        paragraphs: [
          <>
            Genomic testing: Performed in tissues (such as prostate tumor
            tissue) to assess molecular signatures to inform prostate cancer
            treatment decision-making, repeat biopsy, and management.
            <ul>
              <li>
                Example tests:{" "}
                <span>Decipher, Prolaris, GenomeDx, ConfirmMDX</span>
              </li>
            </ul>
          </>,
          <>
            Tumor sequencing: Performed in tumor and sometimes matched normal
            prostate specimens to assess tumor-specific mutations. Being
            performed to inform precision medicine/targeted therapy.
            <ul>
              <li>
                Example labs: <span>Foundation Medicine, Caris</span>
              </li>
            </ul>
          </>,
          <>
            Genetic testing for inherited cancers: Performed on a blood or
            saliva sample to assess for inherited genetic mutations. Informs
            cancer screening, cancer risk reduction, treatment, and cancer risk
            in families.
            <ul>
              <li>
                Example labs: <span>Invitae, Myriad, Ambry, GeneDx, Color</span>
              </li>
            </ul>
          </>,
        ],
        tags: [
          "genetic",
          "genomic",
          "testing",
          "tissue",
          "prostate",
          "tumor",
          "cancer",
          "biopsy",
          "Decipher",
          "Prolaris",
          "GenomeDX",
          "ConfirmMDX",
          "sequencing",
          "mutations",
          "Foundation",
          "medicine",
          "Caris",
          "risk",
          "treatment",
          "Invitae",
          "Myriad",
          "Ambry",
          "GeneDx",
          "Color",
        ],
      },
      {
        name: "Family Cascade Testing",
        topicId: "5",
        audio: "",
        paragraphs: [
          <>
            Once a mutation in a gene is identified in a patient, all blood
            relatives would be recommended to undergo genetic testing for that
            mutation or potentially additional genes based on family history.
            This process is called <span>cascade testing</span>.
          </>,
          <>
            Cascade testing is particularly meaningful to patients when it comes
            to their children and their siblings. However, this is important to
            emphasize as it pertains to all blood relatives.
          </>,
          <>
            Relatives should be referred to a genetic counselor to undergo
            genetic counseling prior to genetic testing.
          </>,
          <>
            Even if genetic results are negative or reveal a variant of
            uncertain significance, family history may indicate that other
            relatives pursue their own genetic testing and genetic counseling.
            This should also be discussed with men and their families.
          </>,
        ],
        tags: [
          "family",
          "cascade",
          "testing",
          "mutation",
          "gene",
          "counselor",
          "history",
          "blood",
        ],
      },
      {
        name: "Multigene Testing",
        topicId: "6",
        audio: "",
        paragraphs: [
          <>
            Multiple commercial genetic testing labs offer either prostate
            cancer-specific panels or multigene panels that may be used for men
            with prostate cancer.
          </>,

          <>
            It is extremely important to know the lab capability for testing,
            assays used, variant reclassification reporting, billing plans, and
            data sharing policies.
          </>,
          <>Examples of labs and gene panels as of September 2020 include:</>,
          <>
            <span>Invitae “Prostate Cancer Panel” (up to 15 genes)</span>
            <ul>
              <li>
                ATM, BRCA1, BRCA2, CHEK2, EPCAM, HOXB13, MLH1, MSH2, MSH6, NBN,
                PMS2, TP53; ADD ON FANCA, PALB2, RAD51D
              </li>
              <li>
                Ability to customize testing. Cancer specific guidelines based
                and preliminary gene panels can be included. Reflex testing is
                available within a specific time frame.
              </li>
            </ul>
            <span>Ambry Genetics “ProstateNext” (14 genes)</span>
            <ul>
              <li>
                ATM, BRCA1, BRCA2, CHEK2, EPCAM, HOXB13, MLH1, MSH2, MSH6, NBN,
                PALB2, PMS2, RAD51D, TP53
              </li>
              <li>
                Ability to customize testing. Less panel options available (does
                not include testing for many preliminary genes). Reflex testing
                is available within a specific time frame. Offers paired RNA
                testing (RNAinsight). Accepts skin punch biopsy specimens for
                testing.{" "}
              </li>
            </ul>
            <span>GeneDx “Prostate Cancer Panel” (12 genes)</span>
            <ul>
              <li>
                ATM, BRCA1, BRCA2, CHEK2, EPCAM, HOXB13, MLH1, MSH2, MSH6, NBN,
                PMS2, TP53.
              </li>
              <li>
                Offers custom cancer panels and custom exome slice. Reflex
                testing is available, but should be indicated in initial order.
                Accepts skin punch biopsy specimens for testing.{" "}
              </li>
            </ul>
            <span>Myriad — No prostate panel but “myRisk” (36 genes)</span>
            <ul>
              <li>
                APC, ATM, AXIN2, BARD1, BMPR1A, BRCA1, BRCA2, BRIP1, CDH1, CDK4,
                CDKN2A, CHEK2, EPCAM, HOXB13, GALNT12, GREM1, MLH1, MSH2, MSH3,
                MSH6, MUTYH, NBN, NTHL1, PALB2, PMS2, POLE POLD1,PTEN, RAD51C,
                RAD51D, RNF43, RPS20, SMAD4, STK11, TP53
              </li>
              <li>HOXB13 included (sequencing only) </li>
              <li>
                Reflex testing from small panels to biggest panel (Myriad
                myRisk) is available, but requires additional specimen. Accepts
                skin punch biopsy specimens for testing.{" "}
              </li>
            </ul>
            <span>
              Color Genomics — No prostate panel but “Hereditary Cancer Panel”
              (30 genes)
            </span>
            <ul>
              <li>
                APC, ATM, BAP1, BARD1, BMPR1A, BRCA1, BRCA2, BRIP1, CDH1, CDK4,
                CDKN2A, CHEK2, EPCAM, GREM1, MITF, MLH1, MSH2, MSH6, MUTYH, NBN,
                PALB2, PMS2, PTEN, POLD1, POLE, RAD51C, RAD51D, SMAD4, STK11,
                TP53
              </li>
              <li>PMS2: excludes exons 11-14</li>
              <li>HOXB13 not included</li>
            </ul>
          </>,
        ],
        tags: [
          "multigene",
          "testing",
          "multiple",
          "genetic",
          "prostate",
          "cancer",
          "panels",
          "Invitae",
          "Ambry",
          "GeneDx",
          "Myriad",
          "myRisk",
          "color",
          "genomics",
          "ATM",
          "BRCA1",
          "BRCA2",
          "CHEK2",
          "EPCAM",
          "HOXB13",
          "MLH1",
          "MSH2",
          "MSH6",
          "NBN",
          "PMS2",
          "TP53",
          "FANCA",
          "PALB2",
          "RAD51D",
          "TP53",
          "breast",
          "ovarian",
          "colorectal",
          "endometrial",
          "melanoma",
          "pancreatic",
          "gastric",
          "APC",
          "BARD1",
          "BMPR1A",
          "BRIP1",
          "CDH1",
          "CDK4",
          "CDKN2A",
          "GREM1",
          "MUTYH",
          "PTEN",
          "POLD1",
          "POLE",
          "RAD51C",
          "SMAD4",
          "STK11",
          "BAP1",
          "MITF",
        ],
      },
      {
        name: "Mutations",
        topicId: "7",
        audio: "",
        paragraphs: [
          <>Not all mutations may be identified in genes.</>,
          <>
            Some genes can be tested with over 95% accuracy in detecting
            mutations, while others may be much lower.
          </>,
          <>
            Some labs only test for specific mutations in genes and do not
            perform full gene sequencing, rearrangement testing, or testing of
            difficult genetic regions, thus potentially missing some mutations.
          </>,
          <>
            Some labs also cannot differentiate the true gene of interest from
            false genes which can exist for certain genes. Thus, testing may not
            be accurately reporting on the genes of interest.
          </>,
          <>
            Therefore, mutations may be missed. Choice of lab becomes critical
            for the best testing to be performed for patients.
          </>,
        ],
        tags: [
          "mutations",
          "genes",
          "sequencing",
          "rearrangement",
          "testing",
          "genetic",
          "region",
          "interest",
          "false",
        ],
      },
    ],
  },
  {
    name: "Understanding Genetic Test Results",
    color: "green",
    sectionId: "3",
    topics: [
      {
        name: "Result Types",
        topicId: "1",
        audio: "",
        paragraphs: [
          <>
            <span>Mutation or Pathogenic Variant:</span> A change in the DNA
            that is associated with a disease, such as cancer.{" "}
            <ul>
              <li>
                There may be management recommendations made based upon finding
                a mutation
              </li>
              <li>
                Additional recommendations can be based upon family history.
                Genetic testing of blood relatives for the mutation is usually
                also recommended
              </li>
              <li>
                Multiple cancer risks may be uncovered for men and their
                families
              </li>
            </ul>
          </>,
          <>
            <span>Variant of Uncertain Significance (VUS):</span> These are
            genetic changes that are not known to associate with cancer at the
            time they are reported to patients. VUS rates are higher with more
            genes tested.{" "}
            <ul>
              <li>
                There are no management changes based upon a finding of a VUS at
                the time of the initial report. Recommendations are based on
                family history. Additional family testing may be recommended
              </li>
              <li>
                The genetic laboratories track VUS over time for more evidence
                of association to cancer. Sometimes, these VUS will be
                reclassified to the interpretation of “mutation”. This could
                mean that cancer screening, risk reduction, or treatment
                recommendations may be made in the future
              </li>
            </ul>
          </>,
          <>
            <span>Negative:</span> No mutations or VUS detected.{" "}
            <ul>
              <li>
                This result is considered “indeterminate” since other genetic
                regions may have contributed to cancer risk. Additional family
                testing may be recommended
              </li>
            </ul>
          </>,
        ],
        tags: [
          "result",
          "type",
          "mutation",
          "pathogenic",
          "variant",
          "DNA",
          "disease",
          "cancer",
          "family",
          "history",
          "blood",
          "risk",
          "uncertain",
          "significance",
          "VUS",
          "change",
          "screening",
          "reduction",
          "treatment",
          "negative",
        ],
      },
      {
        name: "Prostate Cancer Panels",
        topicId: "2",
        audio: "",
        paragraphs: [
          <>
            Not all genes on these panels confer strong risk for prostate
            cancer.{" "}
            <ul>
              <li>
                Strong risk:{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA2,
                </a>{" "}
                <span>HOXB13</span>
              </li>
              <li>
                Some have moderate risk: DNA mismatch repair genes{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  (MLH1, MSH2, PMS2, MSH6, EPCAM), BRCA1, NBN, CHEK2,
                </a>{" "}
                and{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  ATM.
                </a>
              </li>
              <li>
                Some genes only have limited or unknown association to prostate
                cancer risk:{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  PALB2, RAD51D, TP5.
                </a>
              </li>
            </ul>
          </>,
        ],
        tags: [
          "prostate",
          "cancer",
          "panels",
          "genes",
          "risk",
          "BRCA2",
          "HOXB13",
          "DNA",
          "mismatch",
          "repair",
          "MLH1",
          "MSH2",
          "PMS2",
          "MSH6",
          "EPCAM",
          "BRCA1",
          "NBN",
          "CHEK2",
          "ATM",
          "limited",
          "PALB2",
          "RAD51D",
          "TP53",
        ],
      },

      {
        name: "Other Considerations",
        topicId: "3",
        audio: "",
        paragraphs: [
          <>
            It is important to remember that genes on prostate cancer panels
            confer risks for additional cancer for which guidelines may exist
            for screening or management.{" "}
            <ul>
              <li>
                For example, men with{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA2
                </a>{" "}
                mutations would be recommended to start self-breast exams and
                clinical breast exams from age 35 due to higher risk of male
                breast cancer. They would also be recommended to see a high-risk
                gastroenterologist to discuss pancreas imaging due to risk for
                pancreatic cancer (depending on family history) and a
                dermatologist for risk of melanoma
              </li>
              <li>
                Another example is that men with{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  MLH1
                </a>{" "}
                mutations (diagnosis of Lynch syndrome) would be recommended to
                see a gastroenterologist since colonoscopies would need to be
                performed every 1-2 years starting in their early 20’s. Multiple
                additional recommendations are also needed for patients with
                Lynch syndrome
              </li>
            </ul>
          </>,
          <>
            Even if no mutation is found, family history can guide cancer
            screening.{" "}
            <ul>
              <li>
                For example, men with a first-degree relative with colon cancer
                would be recommended to have colonoscopy every 5 years rather
                than every 10 years just based on family history.
              </li>
            </ul>
          </>,
        ],
        tags: [
          "considerations",
          "gene",
          "prostate",
          "cancer",
          "risk",
          "BRCA2",
          "mutations",
          "breast",
          "pancreatic",
          "melanoma",
          "MLH1",
          "Lynch",
          "syndrome",
          "gastroenterologist",
          "dermatologist",
          "pancreas",
          "family",
          "history",
          "colon",
        ],
      },
      {
        name: "Additional Recommendations",
        topicId: "4",
        audio: "",
        paragraphs: [
          <>
            Various types of genetic tests can be chosen based upon patient
            choice and family history:{" "}
            <ul>
              <li>
                <span>Focused panel:</span> includes genes with guidelines
              </li>
              <li>
                <span>Prostate cancer-specific panel:</span> includes genes with
                and without guidelines
              </li>
              <li>
                <span>Large panels:</span> includes genes with and without
                guidelines, and may include genes with limited cancer risk
                information
              </li>
            </ul>
          </>,
          <>
            Multiple cancer risks may be uncovered for the patient and their
            family. Risks for cancers in children may also be found.
          </>,
          <>
            Genetic conditions may be uncovered where testing of the patient’s
            reproductive partner could be important.
          </>,
          <>
            Some cancer risks may not have recommendations for screening or
            management available at this time. Therefore, there may be a
            mutation in a gene identified without recommendations to make based
            upon the finding.
          </>,
        ],
        tags: [
          "recommendations",
          "genetic",
          "test",
          "family",
          "history",
          "focused",
          "panel",
          "prostate",
          "cancer",
          "large",
          "risk",
          "mutation",
          "gene",
        ],
      },
    ],
  },
  {
    name: "Implications of Genetic Testing",
    color: "purple",
    sectionId: "4",
    topics: [
      {
        name: "Screening Implications",
        topicId: "1",
        audio: "",
        paragraphs: [
          <>
            It is recommended to start prostate cancer screening at age 45 for
            male{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2
            </a>{" "}
            carriers and to consider this for male{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA1
            </a>{" "}
            carriers.
          </>,
          <>
            It may be reasonable to offer earlier prostate cancer screening to
            men with{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              HOXB13
            </a>{" "}
            mutations especially if there is a family history of prostate
            cancer.
          </>,
          <>
            Similarly, it may be reasonable to offer earlier prostate cancer
            screening for men with DNA mismatch repair mutations.
          </>,
          <>
            This is also a rapidly evolving area with updates on screening to be
            expected in the near future.
          </>,
        ],
        tags: [
          "screening",
          "implications",
          "BRCA2",
          "BRCA1",
          "prostate",
          "cancer",
          "HOXB13",
          "mutations",
          "family",
          "history",
          "DNA",
          "mismatch",
          "repair",
        ],
      },
      {
        name: "Treatment Implications",
        topicId: "2",
        audio: "",
        paragraphs: [
          <>
            FDA granted accelerated approval to rucaparib for men with
            BRCA-mutated metastatic castration-resistant prostate cancer who
            have been treated with androgen receptor-directed therapy and
            taxane-based chemotherapy. Also, the FDA granted approval for
            olaparib for deleterious or suspected deleterious germline or
            somatic homologous recombination repair (HRR) gene-mutated
            metastatic CRPC in men who have progressed following prior treatment
            with abiraterone or enzalutamide.
          </>,
          <>
            A growing indication for performing genetic testing is to inform
            treatment for men with metastatic prostate cancer.{" "}
            <ul>
              <li>
                US FDA granted olaparib “Breakthrough-Therapy” designation for{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA1/2
                </a>{" "}
                or{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  ATM
                </a>{" "}
                mutated metastatic prostate cancer due to improved response rate
              </li>
              <li>
                Another study showed improved cause-specific survival among men
                with metastatic CRPC and{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA2
                </a>{" "}
                mutations treated with first-line with abiraterone or
                enzalutamide over taxanes
              </li>
              <li>
                Men with DNA mismatch repair gene mutations and metastatic
                disease may be eligible for checkpoint inhibitors such as
                pembrolizumab
              </li>
              <li>
                Multiple clinical trials are also available for men with
                metastatic disease based on genetic test results
              </li>
            </ul>
          </>,
          <>
            Men considering active surveillance with early stage disease may
            also benefit from genetic testing.{" "}
            <ul>
              <li>
                Upgrading of biopsies of men on active surveillance for
                early-stage prostate cancer was more significant among{" "}
                <a href='/library/category/2/topic/1#subtitle' target='_blank'>
                  BRCA2
                </a>{" "}
                carriers.
              </li>
            </ul>
          </>,
        ],
        tags: [
          "treatment",
          "implications",
          "genetic",
          "testing",
          "prostate",
          "cancer",
          "olaparib",
          "BRCA1",
          "BRCA2",
          "ATM",
          "mutate",
          "metastatic",
          "CRPC",
          "BRCA2",
          "abiraterone",
          "enzalutamide",
          "taxanes",
          "pembrolizumab",
          "disease",
          "biopsy",
        ],
      },
    ],
  },
  {
    name: "Risks of Genetic Testing",
    color: "orange",
    sectionId: "5",
    topics: [
      {
        name: "Discrimination",
        topicId: "1",
        audio: "",
        paragraphs: [
          <>
            <span>The Genetic Information Nondiscrimination Act (GINA)</span>{" "}
            is important to understand when performing  genetic testing for pretest informed consent. This information must be discussed with patients before genetic testing.
          </>,
          <>
            <p>
              <span>Health insurance:</span>
            </p>
            <ul>
              <li>
                <span>Covered: </span>
                 GINA protects patients with mutations from discrimination by their health insurance for most insurance plans.
              </li>


              <li>
                <span>Not covered: </span>
                 Health insurance for veterans, US military through Tricare, Indian Health Service, Federal Health Benefits Plans (they often have their own protections).
              </li>
            </ul>
          </>,
          <>
            <p>
              <span>Employment: </span>
            </p>
            <ul>
              <li>
                <span>Covered: </span>
                 GINA protects individuals who carry mutations from being discriminated by their employers for most employment scenarios.
              </li>
              <li>
                <span>Not covered: </span>
                 Small businesses with less than 15 employees.
              </li>
            </ul>
          </>,
          <>
            <span>Life insurance: GINA does not cover life insurance. </span> Therefore, patients with mutations may not be eligible for life insurance or may have policy issues due to deeming a mutation as having a "pre-existing condition."
          </>,
          <>
            <span>Additional insurance plans: </span> GINA does not cover long-term care or disability insurance.
          </>,
          <>
            <span>Key point: </span> It is critical to discuss the GINA law with patients <u><em>before genetic testing</em></u> for patients to consider these issues and make an informed decision for genetic testing.
          </>,
        ],
        tags: [
          "discrimination",
          "genetic",
          "information",
          "act",
          "GINA",
          "mutations",
          "health",
          "insurance",
          "veteran",
          "military",
          "Indian",
          "federal",
          "benefits",
          "disability",
        ],
      },
      {
        name: "Reproductive Implications",
        topicId: "2",
        audio: "",
        paragraphs: [
          <>
            Several genes on prostate cancer panels can be associated with
            childhood conditions if children inherit mutations affecting both
            copies of a gene — one from father and one from mother.
          </>,
          <>
            An example is when two copies of the{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2
            </a>{" "}
            gene are abnormal, resulting in Fanconi anemia. This is a rare
            disorder that leads to bone marrow failure, leukemia, and other
            tumors in childhood.
          </>,
          <>
            Men with{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              BRCA2
            </a>{" "}
            mutations who are considering having children may wish to discuss
            that their female partner also undergoes genetic testing to
            determine the risk for Fanconi anemia.
          </>,
          <>
            Some of the other genes on prostate cancer panels with reproductive
            implications include{" "}
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              ATM
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              BRCA1
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              MLH1
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              MLH2
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              PMS2
            </a>
            ,
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              MSH6
            </a>
            , and
            <a href='/library/category/2/topic/1#subtitle' target='_blank'>
              {" "}
              NBN.
            </a>
          </>,
        ],
        tags: [
          "reproductive",
          "implications",
          "gene",
          "prostate",
          "cancer",
          "panel",
          "inherit",
          "mutations",
          "BRCA2",
          "Fanconi",
          "anemia",
          "leukemia",
          "children",
          "ATM",
          "BRCA1",
          "MLH1",
          "MSH2",
          "PMS2",
          "MSH6",
          "NBN",
        ],
      },
    ],
  },
]
