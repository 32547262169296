import React from "react";
import "./AudioPlayer.scss"
import ReactAudioPlayer from 'react-audio-player';




function AudioPlayer(props) {
	return (
		<div className='audio-text-wrapper'>
			<div className='audio-text'>
				<h1>{props.title}</h1>
			</div>

			<ReactAudioPlayer 
				controls
				src={props.song}
			/>

		</div>
	);

}

export default AudioPlayer;
