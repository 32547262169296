import React from 'react'
import { useHistory } from 'react-router-dom'

import Banner from '../../components/Banner/Banner'
import BannerBack from '../../components/Banner/BannerBack/BannerBack'

export const References = () => {
	const history = useHistory()

	return (
		<div>
			<Banner color='aqua' flipped hideDesktopWave>
				<div className='hide-desktop'>
					<BannerBack onClick={() => history.push('/more')} />
				</div>
			</Banner>
			<h1>References</h1>
			<p id='ref-1'>
				1. Amadou A, Waddington Achatz MI, Hainaut P. Revisiting tumor
				patterns and penetrance in germline TP53 mutation carriers:
				temporal phases of Li-Fraumeni syndrome. Curr Opin Oncol.
				2018;30(1):23-29.
			</p>
			<p id='ref-2'>
				2. Angele S, Falconer A, Edwards SM, et al. ATM polymorphisms as
				risk factors for prostate cancer development. Br J Cancer.
				2004;91(4):783-787.
			</p>
			<p id='ref-3'>
				3. Assessing your patient’s hereditary cancer risk. American
				Society of Clinical Oncology. Available at:&nbsp;
				<a href='https://www.asco.org' target='_blank' rel='noopener noreferrer'>https://www.asco.org</a>.
			</p>
			<p id='ref-4'>
				4. Bancroft EK, Page EC, Castro E, et al. Targeted prostate
				cancer screening in BRCA1 and BRCA2 mutation carriers: results
				from the initial screening round of the IMPACT study. Eur Urol
				2014;66:489-499.
			</p>
			<p id='ref-5'>
				5. Bauer CM, Ray AM, Halstead-Nussloch BA, et al. Hereditary
				prostate cancer as a feature of Lynch syndrome. Fam Cancer.
				2011;10(1):37-42.
			</p>
			<p id='ref-6'>
				6. Benafif S, Kote-Jarai Z, Eeles RA. A review of prostate
				cancer genome-wide association studies (GWAS). Cancer Epidemiol
				Biomarkers Prev 2018;27(8):845-857.
			</p>
			<p id='ref-7'>
				7. Breast Cancer Linkage C. Cancer risks in BRCA2 mutation
				carriers. J Natl Cancer Inst. 1999;91(15):1310-1316.
			</p>
			<p id='ref-8'>
				8. Carter, H. B., Helfand, B., Mamawala, M. et al.: Germline
				Mutations in ATM and BRCA1/2 Are Associated with Grade
				Reclassification in Men on Active Surveillance for Prostate
				Cancer. Eur Urol, 2018
			</p>
			<p id='ref-9'>
				9. Castro E, Goh C, Olmos D, et al: Germline BRCA mutations are
				associated with higher risk of nodal involvement, distant
				metastasis, and poor survival outcomes in prostate cancer. J
				Clin Oncol 31: 1748-1757, 2013
			</p>
			<p id='ref-10'>
				10. Castro E, Romero-Laorden N, Del Pozo A, Lozano R, Medina A,
				Puente J, Piulats JM, Lorente D, Saez MI, Morales-Barrera R,
				Gonzalez-Billalabeitia E, Cendón Y, García-Carbonero I, Borrega
				P, Mendez Vidal MJ, Montesa A, Nombela P, Fernández-Parra E,
				Gonzalez Del Alba A, Villa-Guzmán JC, Ibáñez K, Rodriguez-Vida
				A, Magraner-Pardo L, Perez-Valderrama B, Vallespín E, Gallardo
				E, Vazquez S, Pritchard CC, Lapunzina P, Olmos D. PROREPAIR-B: A
				Prospective Cohort Study of the Impact of Germline DNA Repair
				Mutations on the Outcomes of Patients With Metastatic
				Castration-Resistant Prostate Cancer. J Clin Oncol. 2019 Feb
				20;37(6):490-503. doi: 10.1200/JCO.18.00358. Epub 2019 Jan 9.
			</p>
			<p id='ref-11'>
				11. Carroll PR, et al. NCCN Clinical Practice Guidelines in Oncology
				(NCCN Guidelines). Prostate Cancer Early Detection. Version
				1.2019. © 2019 National Comprehensive Cancer Network, Inc
				Accessed October 11, 2019. To view the most recent version,
				visit NCCN.org. Linked with permission from the National
				Comprehensive Cancer Network, Inc. All rights reserved. The NCCN
				Guidelines® and illustrations herein may not be reproduced in
				any form for any purpose without the express written permission
				of NCCN. To view the most recent and complete version of the
				NCCN Guidelines, go online to <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'> NCCN.org</a>.
				The NCCN Guidelines are a work in progress that may be refined as often as new
				significant data becomes available.
			</p>
			<p id="ref-12">
				12. Cheng HH, Sokolova AO, Schaeffer EM, Small EJ and Higano CS.
				Germline and somatic mutations in prostate cancer for the
				clinician. J Natl Compr Canc Netw: 2019; 17 (5): 515-521.
			</p>
			<p id='ref-13'>
				13. Conti DV, Wang K, Sheng X, et al. Two Novel Susceptibility
				Loci for Prostate Cancer in Men of African Ancestry. J Natl
				Cancer Inst. 2017;109(8).
			</p>
			<p id='ref-14'>
				14. Cybulski C, Gorski B, Debniak T, et al. NBS1 is a prostate
				cancer susceptibility gene. Cancer Res. 2004;64(4):1215-1219.
			</p>
			<p id='ref-15'>
				15. Daly MB, et.al. NCCN Clinical Practice Guidelines in Oncology
				(NCCN Guidelines) Genetic/Familial High-Risk Assessment: Breast
				and Ovarian. Version 3.2019© 2019 National Comprehensive Cancer
				Network, Inc. Available at NCCN.org. Accessed: August 23. Linked
				with permission from the National Comprehensive Cancer Network,
				Inc. All rights reserved. The NCCN Guidelines® and illustrations
				herein may not be reproduced in any form for any purpose without
				the express written permission of NCCN. To view the most recent
				and complete version of the NCCN Guidelines, go online to <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'> NCCN.org</a>.
				The NCCN Guidelines are a work in progress that may be
				refined as often as new significant data becomes available.
			</p>
			<p id='ref-16'>
				16. Ewing CM, Ray AM, Lange EM, et al. Germline mutations in
				HOXB13 and prostate-cancer risk. N Engl J Med 366:141-9, 2012.
			</p>
			<p id='ref-17'>
				17. Genetics of Prostate Cancer (PDQ®)–Health Professional
				Version. National Cancer Institute. Available at:&nbsp;
				<a href='https://www.cancer.gov/' target='_blank' rel='noopener noreferrer'>https://www.cancer.gov/</a>
			</p>
			<p id='ref-18'>
				18. Giri VN, Knudsen KE, Kelly WK, Abida W, Andriole GL, Bangma
				CH, Bekelman JE, Benson MC, Blanco A, Burnett A et al: Role of
				Genetic Testing for Inherited Prostate Cancer Risk: Philadelphia
				Prostate Cancer Consensus Conference 2017. J Clin Oncol 2018,
				36(4):414-424.
			</p>
			<p id='ref-19'>
				19. Giri VN, Hegarty SE, Hyatt C, O’Leary E, Garcia J, Knudsen
				KE, Kelly WK, and Gomella LG. Germline Genetic Testing for
				Inherited Prostate Cancer in Practice: Implications for Genetic
				Testing, Precision Therapy, and Cascade Testing. The Prostate
				2019. Prostate. 2019 Mar;79(4):333-339. doi: 10.1002/pros.23739.
				Epub 2018 Nov 18.
			</p>
			<p id='ref-20'>
				20. Giri VN, Hyatt C, and Gomella LG. Germline testing for men
				with prostate cancer: Navigating an expanding new world of
				genetic evaluation for precision therapy and precision
				management. J Clin Oncol 2019. doi: 10.1200/JCO.18.02181.
			</p>
			<p id='ref-21'>
				21. Giri VN, Obeid E, Hegarty SE, Gross L, Bealin L, Hyatt C,
				Fang CY, PhD, and Leader A. Understanding of Multigene Test
				Results Among Males Undergoing Germline Testing for Inherited
				Prostate Cancer: Implications for Genetic Counseling. Prostate.
				2018 Apr 14. doi: 10.1002/pros.23535. [Epub ahead of print]
			</p>
			<p id='ref-22'>
				22. Giri VN and Beebe-Dimmer J. Familial prostate cancer. Semin
				Oncol. 2016 Oct;43(5):560-565
			</p>
			<p id='ref-23'>
				23. Hall MJ, Forman AD, Pilarski R, et al. Gene panel testing
				for inherited cancer risk. JNCCN 2014 Sep;12(9):1339-1346.
			</p>
			<p id='ref-24'>
				24. Hampel H, Bennett R, Buchanan A, Pearlman R, Wiesner G. A
				practice guideline from the American College of Medical Genetics
				and Genomics and the National Society of Genetic Counselors:
				Referral indications for cancer predisposition assessment. Genet
				Med 17:70-87, 2014 22.
			</p>
			<p id='ref-25'>
				25. Horton R, Lucassen A. Consent and Autonomy in the Genomics
				Era. Curr Genet Med Rep. 2019;7(2):85-91. doi:
				10.1007/s40142-019-00164-9. Epub 2019 May 2. Review. PubMed
				PMID: 31281738; PubMed Central PMCID: PMC6584705.
			</p>
			<p id='ref-26'>
				26. Institute of Medicine (US) Committee on Assessing Genetic
				Risks: Andrews LB, Fullarton JE, Holtzman NA, et al (eds):
				Assessing Genetic Risks: Implications for Health and Social
				Policy. Washington, DC, National Academies Press, 1994&nbsp;
				<a href='https://www.ncbi.nlm.nih.gov/books/NBK236044' target='_blank' rel='noopener noreferrer'>
					Assessing Genetic Risks
				</a>
				.
			</p>
			<p id='ref-27'>
				27. Kote-Jarai Z, Leongamornlert D, Saunders E, Tymrakiewicz M,
				Castro E, Mahmud N, Guy M, Edwards S, O’Brien L, Sawyer E et al:
				BRCA2 is a moderate penetrance gene contributing to young-onset
				prostate cancer: implications for genetic testing in prostate
				cancer patients. Br J Cancer 2011, 105(8):1230-1234.
			</p>
			<p id='ref-28'>
				28. Le DT, Uran JN, Wang H et al. PD-1 Blockade in Tumors with
				Mismatch-Repair Deficiency. NEJM 2015; 372 (26): 2509.
			</p>
			<p id='ref-29'>
				29. Lehrer S, Fodor F, Stock RG, et al.: Absence of 185delAG
				mutation of the BRCA1 gene and 6174delT mutation of the BRCA2
				gene in Ashkenazi Jewish men with prostate cancer. Br J Cancer
				78 (6): 771-3, 1998.
			</p>
			<p id='ref-30'>
				30. Leongamornlert DA, Saunders EJ, Wakerell S, et al. Germline
				DNA Repair Gene Mutations in Young-onset Prostate Cancer Cases
				in the UK: Evidence for a More Extensive Genetic Panel. Eur
				Urol. 2019;76(3):329-337.
			</p>
			<p id='ref-31'>
				31. Mai PL, Best AF, Peters JA, et al. Risks of first and
				subsequent cancers among TP53 mutation carriers in the National
				Cancer Institute Li-Fraumeni syndrome cohort. Cancer.
				2016;122(23):3673-3681.
			</p>
			<p id='ref-32'>
				32. Mateo J, Carreira S, Sandhu S, et al. DNA-repair defects and
				olaparib in metastatic prostate cancer. N Engl J Med 373(18):
				1697-708, 2015.
			</p>
			<p id='ref-33'>
				33. Mersch J, Brown N, Pirzadeh-Miller S, et al: Prevalence of
				variant reclassiﬁcation following hereditary cancer genetic
				testing. JAMA 320:1266-1274, 2018
			</p>
			<p id='ref-34'>
				34. Meyer A, Wilhelm B, Dork T, et al. ATM missense variant
				P1054R predisposes to prostate cancer. Radiother Oncol.
				2007;83(3):283-288.
			</p>
			<p id='ref-35'>
				35. Mohler JL, Antonarakis ES, Armstrong AJ, et al. NCCN
				clinical practice guidelines in oncology, prostate cancer. J
				Natl Compr Canc Netw: 2019; Version 4.2019. © 2019 National
				Comprehensive Cancer Network, Inc. Accessed August 23, 2019. To
				view the most recent version, visit <a href="https://www.nccn.org/" target='_blank' rel='noopener noreferrer'> NCCN.org</a>.
			</p>
			<p id='ref-36'>
				36. Momozawa Y, Iwasaki Y, Hirata M, et al. Germline pathogenic
				variants in 7,636 Japanese patients with prostate cancer and
				12,366 controls. J Natl Cancer Inst. 2019.
			</p>
			<p id='ref-37'>
				37. Na, Rong, S. Lilly Zheng, Misop Han, Hongjie Yu, Deke Jiang,
				Sameep Shah, Charles M. Ewing, et al. 2017. “Germline Mutations
				in ATM and BRCA1/2 Distinguish Risk for Lethal and Indolent
				Prostate Cancer and Are Associated with Early Age at Death.”
				European Urology.&nbsp;
				<a href='https://doi.org/10.1016/j.eururo.2016.11.033' target='_blank' rel='noopener noreferrer'>
					Germline Mutations
				</a>
				.
			</p>
			<p id='ref-38'>
				38. Nicolosi P, Ledet E, Yang S, et al. Prevalence of germline
				variants in prostate cancer and implications for current genetic
				testing guidelines. JAMA oncology: 2019; 5 (4): 523-528.
			</p>
			<p id='ref-39'>
				39. Nyberg T, Frost D, Barrowdale D, et al. Prostate Cancer
				Risks for Male BRCA1 and BRCA2 Mutation Carriers: A Prospective
				Cohort Study. Eur Urol. 2019.
			</p>
			<p id='ref-40'>
				40. Oh M, Alkhushaym N, Fallatah S, Althagafi A, Aljadeed R,
				Alsowaida Y, et al. The association of BRCA1 and BRCA2 mutations
				with prostate cancer risk, frequency, and mortality: A
				meta-analysis. Prostate. 2019 Jun;79(8):880-895.
			</p>
			<p id='ref-41'>
				41. Pakkanen S, Wahlfors T, Siltanen S, et al. PALB2 variants in
				hereditary and unselected Finnish prostate cancer cases. J Negat
				Results Biomed. 2009;8:12.
			</p>
			<p id='ref-42'>
				42. Pritchard CC, Mateo J, Walsh MF, De Sarkar N, Abida W,
				Beltran H, Garofalo A, Gulati R, Carreira S, Eeles R, Elemento
				O, Rubin MA, Robinson D, Lonigro R, Hussain M, Chinnaiyan A,
				Vinson J, Filipenko J, Garraway L, Taplin ME, AlDubayan S, Han
				GC, Beightol M, Morrissey C, Nghiem B, Cheng HH, Montgomery B,
				Walsh T, Casadei S, Berger M, Zhang L, Zehir A, Vijai J, Scher
				HI, Sawyers C, Schultz N, Kantoff PW, Solit D, Robson M, Van
				Allen EM, Offit K, de Bono J, Nelson PS. Inherited DNA-Repair
				Gene Mutations in Men with Metastatic Prostate Cancer. N Engl J
				Med. 2016 Aug 4;375(5):443-53. doi: 10.1056/NEJMoa1603144. Epub
				2016 Jul 6.
			</p>
			<p id='ref-43'>
				43. Rana HQ, Gelman R, LaDuca H, et al. Differences in TP53
				Mutation Carrier Phenotypes Emerge From Panel-Based Testing. J
				Natl Cancer Inst. 2018;110(8):863-870.
			</p>
			<p id='ref-44'>
				44. Raymond VM, Mukherjee B, Wang F, et al. Elevated risk of
				prostate cancer among men with Lynch syndrome. J Clin Oncol
				31:1713-1718, 2013.
			</p>
			<p id='ref-45'>
				45. Riley BD, Culver JO, Skrzynia C, Senter LA, Peters JA,
				Costalas JW, Callif-Daley F, Grumet SC, Hunt KS, Nagy RS,
				McKinnon WC, Petrucelli NM, Bennett RL, Trepanier AM. Essential
				elements of genetic cancer risk assessment, counseling, and
				testing: updated recommendations of the National Society of
				Genetic Counselors. J Genet Couns. 2012 Apr;21(2):151-61.
				doi:10.1007/s10897-011-9462-x. Epub 2011 Dec 2. PubMed PMID:
				22134580.
			</p>
			<p id='ref-46'>
				46. Robinson D, Van Allen EM, Wu YM, Schultz N, Lonigro RJ,
				Mosquera JM, Montgomery B, Taplin ME, Pritchard CC, Attard G,
				Beltran H, Abida W, Bradley RK, Vinson J, Cao X, Vats P, Kunju
				LP, Hussain M, Feng FY, Tomlins SA, Cooney KA, Smith DC, Brennan
				C, Siddiqui J, Mehra R, Chen Y, Rathkopf DE, Morris MJ, Solomon
				SB, Durack JC, Reuter VE, Gopalan A, Gao J, Loda M, Lis RT,
				Bowden M, Balk SP, Gaviola G, Sougnez C, Gupta M, Yu EY,
				Mostaghel EA, Cheng HH, Mulcahy H, True LD, Plymate SR, Dvinge
				H, Ferraldeschi R, Flohr P, Miranda S, Zafeiriou Z, Tunariu N,
				Mateo J, Perez-Lopez R, Demichelis F, Robinson BD, Schiffman M,
				Nanus DM, Tagawa ST, Sigaras A, Eng KW, Elemento O, Sboner A,
				Heath EI, Scher HI, Pienta KJ, Kantoff P, de Bono JS, Rubin MA,
				Nelson PS, Garraway LA, Sawyers CL, Chinnaiyan AM. Integrative
				clinical genomics of advanced prostate cancer. Cell. 2015 May
				21;161(5):1215-1228. doi: 10.1016/j.cell.2015.05.001. Erratum
				in: Cell. 2015 Jul 16;162(2):454
			</p>
			<p id='ref-47'>
				47. Robson ME, Bradbury AR, Arun B, et al: American Society of
				Clinical Oncology policy statement update: Genetic and genomic
				testing for cancer susceptibility. J Clin Oncol 33:3660-3667,
				2015
			</p>
			<p id='ref-48'>
				48. Rusak B, Kluzniak W, Wokolorczykv D, et al. Inherited NBN
				Mutations and Prostate Cancer Risk and Survival. Cancer Res
				Treat. 2019;51(3):1180-1187.
			</p>
			<p id='ref-49'>
				49. Ryan S, Jenkins MA, Win AK: Risk of prostate cancer in Lynch
				syndrome: a systematic review and meta-analysis. Cancer
				Epidemiol Biomarkers Prev 2014, 23(3):437-449.
			</p>
			<p id='ref-50'>
				50. Schweizer MT, Antonarakis ES, Bismar TA, Guedes LB, Cheng
				HH, Tretiakova MS, et al. Genomic Characterization of Prostatic
				Ductal Adenocarcinoma Identifies a High Prevalence of DNA Repair
				Gene Mutations. JCO Precision Oncology 2019. DOI:
				10.1200/PO.18.00327
			</p>
			<p id='ref-51'>
				51. Schumacher FR, Al Olama AAA, Berndt SI et al. Association
				analyses of more than 140,000 men identify 63 new prostate
				cancer susceptibility loci. Nat Genet 2018;50(7):928-936.
			</p>
			<p id='ref-52'>
				52. Seibert TM, Fan CC, Wang Y et al. Polygenic hazard score to
				guide screening for aggressive prostate cancer: development and
				validation in large scale cohorts. BMJ 2018; 360:j5757.
			</p>
			<p id='ref-53'>
				53. Southey MC, Goldgar DE, Winqvist R, et al. PALB2, CHEK2 and
				ATM rare variants and cancer risk: data from COGS. J Med Genet.
				2016;53(12):800-811.
			</p>
			<p id='ref-54'>
				54. Struewing JP, Hartge P, Wacholder S, et al.: The risk of
				cancer associated with specific mutations of BRCA1 and BRCA2
				among Ashkenazi Jews. N Engl J Med 336 (20): 1401-8, 1997
			</p>
			<p id='ref-55'>
				55. Thompson D, Easton DF. Cancer Incidence in BRCA1 mutation
				carriers. Journal of the National Cancer Institute.
				2002;94(18):1358-1365.
			</p>
			<p id='ref-56'>
				56. Tryggvadottir L, Vidarsdottir L, Thorgeirsson T, et al.
				Prostate cancer progression and survival in BRCA2 mutation
				carriers. J Natl Cancer Inst. 2007;99(12):929-935.
			</p>
			<p id='ref-57'>
				57. Wang Y, Dai B, Ye D. CHEK2 mutation and risk of prostate
				cancer: a systematic review and meta-analysis. Int J Clin Exp
				Med. 2015;8(9):15708-15715.
			</p>
			<p id='ref-58'>
				58. Weitzel JN, Blazer KR, MacDonald DJ, Culver JO, Offit K.
				Genetics, genomics, and cancer risk assessment: State of the Art
				and Future Directions in the Era of Personalized Medicine. CA
				Cancer J Clin. 2011 Sep-Oct;61(5):327-59. doi:
				10.3322/caac.20128. Epub 2011 Aug 19. Review. PubMed PMID:
				21858794; PubMed Central PMCID: PMC3346864.
			</p>
		</div>
	)
}


