import React from "react";
import "./HelixButton.scss"
import  HelixIcon  from "../../assets/svg/button.svg";



var sectionStyleButton = {	
	backgroundImage: `url("${HelixIcon}")`
}

function HelixButton({ onClick, children }) {

	return (
		// <div className='button-wrapper'>
			<button className="helix-button"
				 style={sectionStyleButton}
				 onClick={onClick} 
				 onMouseUp={() => (document.activeElement.toString() === '[object HTMLButtonElement]' ) ? document.activeElement.blur() : ""  } >
				<div>{children}</div>
			</button>
		// </div>	
	);
}

export default HelixButton;
