import React from 'react'

import './WaveIcon.scss'

import { ReactComponent as Wave } from '../../assets/svg/wave-icon-S1.svg'

function WaveIcon({ color, flipped, mdup }) {
	return (
		<Wave
			className={`wave-icon ${color ? color : `aqua`} ${
				flipped ? 'flipped' : ''
			} ${mdup ? 'md-up-only' : ''}`}
		/>
	)
}

export default WaveIcon
