import React, { useCallback } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';


import Banner from '../../components/Banner/Banner'
import HelixButton from '../../components/HelixButton/HelixButton'

export const Results = ({ match }) => {
	const history = useHistory()

	const exitScreening = useCallback(() => {
		history.push('/')
	}, [history])

	return (
		<div>
			<Banner color='aqua' colorfill flipped hideDesktopWave />
			<Route path={`${match.path}/exit-([0-9])`}>
				<h1>
					Genetic counseling and genetic testing may be useful for
					therapeutic and clinical trial options.
				</h1>
			</Route>
			<Route exact path={`${match.path}/exit-1`}>
				<p>
					Men with metastatic disease have higher rates of mutations
					in DNA repair genes such as BRCA2, BRCA1, and DNA
					mismatch repair genes. In addition, hereditary cancer
					syndromes may be uncovered that inform cancer screening and
					risk reduction for men and their families.
				</p>
				<p>
					<b>Key references:
					 <Link to="/references#ref-12"> 12</Link>,
					 <Link to="/references#ref-17"> 17</Link>,
					 <Link to="/references#ref-18"> 18</Link>,
					 <Link to="/references#ref-28"> 28</Link>,
					 <Link to="/references#ref-32"> 32</Link>,
					 <Link to="/references#ref-42"> 42</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-2`}>
				<p>
					Men with prostate cancer with intraductal histology have
					enrichment of mutations in DNA repair genes such as BRCA1,
					BRCA2, and MSH2. In addition, hereditary cancer syndromes
					may be uncovered that inform cancer screening and risk
					reduction for men and their families.
				</p>
				<p>
					<b>Key references: 
						<Link to="/references#ref-17"> 17</Link>,
						<Link to="/references#ref-50"> 50</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-3`}>
				<p>
					Men with higher stage or higher risk disease may have
					greater chance of progression to metastatic disease upon
					which genetic information may inform metastatic disease
					management. Clinical trials may also be options based on
					genetic results. In addition, hereditary cancer syndromes
					may be uncovered that inform cancer screening and risk
					reduction for men and their families.
				</p>
				<p>
					<b>Key references: 
						<Link to="/references#ref-12"> 12</Link>,
						<Link to="/references#ref-17"> 17</Link>,
						<Link to="/references#ref-18"> 18</Link>,
						<Link to="/references#ref-19"> 19</Link>,
						<Link to="/references#ref-42"> 42</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-4`}>
				<p>This population may have higher rates of BRCA mutations.</p>
				<p>
					<b>Key references: 
						<Link to="/references#ref-17"> 17</Link>,
						<Link to="/references#ref-29"> 29</Link>,
						<Link to="/references#ref-54"> 54</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-5`}>
				<p>
					Men with a family history of men with prostate cancer,
					particularly if diagnosed at a young age, may be at risk for
					development of prostate cancer. Genetic testing may uncover
					hereditary cancer syndromes particularly due to BRCA2 or
					HOXB13, though other genes may also be tested. Results may
					inform cancer screening for men and their families.
				</p>
				<p>
					<b>Key references: 
					 <Link to="/references#ref-12"> 12</Link>,
					 <Link to="/references#ref-16"> 16</Link>,
					 <Link to="/references#ref-16"> 17</Link>,
					 <Link to="/references#ref-18"> 18</Link>,
					 <Link to="/references#ref-22"> 22</Link>,
					 <Link to="/references#ref-33"> 33</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-6`}>
				<p>
					Men with a family history of men who passed away from
					prostate cancer may be at risk for development of prostate
					cancer, and potentially aggressive prostate cancer. Genetic
					testing may uncover hereditary cancer syndromes,
					particularly due to BRCA2 or HOXB13, though other genes may
					also be tested. Results may inform cancer screening for men
					and their families.
				</p>
				<p>
					<b>Key references: 
					 <Link to="/references#ref-12"> 12</Link>, 
					 <Link to="/references#ref-16"> 16</Link>, 
					 <Link to="/references#ref-17"> 17</Link>, 
					 <Link to="/references#ref-18"> 18</Link>, 
					 <Link to="/references#ref-22"> 22</Link>, 
					 <Link to="/references#ref-33"> 33</Link> 
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/exit-7`}>
				<p>
					Family history may indicate hereditary cancer syndromes such
					as hereditary breast and ovarian cancer (HBOC), Lynch
					syndrome, or hereditary prostate cancer. Cancers linked with
					HBOC include: breast (male and female), ovarian, pancreatic,
					prostate (particularly lethal disease), and melanoma.
					Cancers linked with Lynch syndrome include: colorectal,
					ovarian, uterine, pancreatic, gastric, small bowel, upper
					tract urothelial, and kidney cancers. Prostate cancer has
					moderate association to Lynch syndrome. Hereditary prostate
					cancer is associated with prostate cancer in three
					generations, early-onset disease, or multiple members of a
					nuclear family with prostate cancer. Genetic testing may
					inform risk for prostate cancer, risk for additional
					cancers, and inform cancer screening, risk reduction, or
					treatment for men and their families.
				</p>
				<p>
					<b>Key references: 
						<Link to="/references#ref-12"> 12</Link>,
						<Link to="/references#ref-16"> 16</Link>,
						<Link to="/references#ref-17"> 17</Link>,
						<Link to="/references#ref-18"> 18</Link>,
						<Link to="/references#ref-22"> 22</Link>,
						<Link to="/references#ref-33"> 33</Link>,
						<Link to="/references#ref-44"> 44</Link>,
						<Link to="/references#ref-49"> 49</Link>
					</b>
				</p>
			</Route>
			<Route exact path={`${match.path}/end`}>
				<h1>Your patient may not meet genetic testing criteria.</h1>
				<p>
					They may still consider genetic counseling and genetic
					testing which may require out of pocket.
				</p>
			</Route>
			<p>
				<HelixButton centered onClick={exitScreening}>
					Exit Screening
				</HelixButton>
			</p>
		</div>
	)
}
