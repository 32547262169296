import React from "react"
import Home from "./Home"
import { Route } from 'react-router-dom'
import Category from "../../components/Category/Category"
import Topic from "../../components/Topic/Topic"


export const Library = ({match}) => {
	return (
    <div>
			<Route exact path={`${match.path}`} component={Home} />	 
			<Route exact path={`${match.path}/category/:section`} component={Category} />	 
			<Route exact path={`${match.path}/category/:section/topic/:subSection`} component={Topic} />	 
  
    </div>
	)
}









