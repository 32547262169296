import {
  PatientQuestion,
  RelativeQuestion,
  HistoryQuestion,
} from "../../components/Question"

export default {
  1: {
    component: PatientQuestion,
    header: "Does your patient have prostate cancer?",
    options: [
      { text: "Yes", path: "2" },
      { text: "No", path: "9" },
      { text: "Don't know", path: "9" },
    ],
  },
  2: {
    component: PatientQuestion,
    header: "Does your patient have metastatic prostate cancer?",
    options: [
      { text: "Yes", path: "exit-1" },
      { text: "No", path: "3" },
      { text: "Don't know", path: "3" },
    ],
  },
  3: {
    component: PatientQuestion,
    header:
      "Is your patient of Ashkenazi Jewish ancestry on either side of the family?",
    options: [
      { text: "Yes", path: "exit-4" },
      { text: "No", path: "4" },
      { text: "Don't know", path: "4" },
    ],
  },
  4: {
    component: PatientQuestion,
    header: "Does your patient have intraductal or cribriform pathology?",
    options: [
      { text: "Yes", path: "exit-2" },
      { text: "No", path: "5" },
      { text: "Don't know", path: "5" },
    ],
  },
  5: {
    component: PatientQuestion,
    header: "What is your patient's T stage?",
    options: [
      { text: "T1", path: "6" },
      { text: "T2", path: "6" },
      { text: "T3", path: "exit-3" },
      { text: "T4", path: "exit-3" },
    ],
  },
  6: {
    component: PatientQuestion,
    header: "What was your patient's PSA at diagnosis?",
    options: [
      { text: "<10", path: "7" },
      { text: "10-20", path: "7" },
      { text: ">20", path: "exit-3" },
    ],
  },
  7: {
    component: PatientQuestion,
    header: "What is your patient's grade group?",
    options: [
      { text: "Grade Group 1", path: "8" },
      { text: "Grade Group 2", path: "8" },
      { text: "Grade Group 3", path: "8" },
      { text: "Grade Group 4", path: "exit-3" },
      { text: "Grade Group 5", path: "exit-3" },
    ],
  },
  8: {
    component: PatientQuestion,
    header: "Is your patient of Ashkenazi Jewish ancestry?",
    options: [
      { text: "Yes", path: "exit-4" },
      { text: "No", path: "9" },
      { text: "Don't know", path: "9" },
    ],
  },
  9: {
    // Component handles Options due to complexity
    component: RelativeQuestion,
    header:
      "Have any of the following relatives had prostate cancer? (choose all that apply)",
    nextQuestion: "10",
  },
  10: {
    component: HistoryQuestion,
    header:
      "Did any of the following relatives pass away from prostate cancer? (choose all that apply)",
    nextQuestion: "11",
    addDocCount: 1,
    options: [
      { text: "Father", isSelected: null, additonalDoc: true },
      { text: "Brother(s)", isSelected: null, additonalDoc: true },
      { text: "Grandfather(s)", isSelected: null },
      { text: "Uncles(s)", isSelected: null },
      { text: "Cousin(s)", isSelected: null },
    ],
    resultPage: 6,
  },
  11: {
    component: HistoryQuestion,
    header:
      "Does your patient have any relatives with any of the following cancers? (choose all that apply)",
    nextQuestion: "end",
    addDocCount: 3,
    options: [
      { text: "Ovarian", isSelected: null, additonalDoc: true },
      { text: "Pancreatic", isSelected: null, additionalDoc: true },
      {
        text: "Metastatic Prostate",
        isSelected: null,
        additionalDoc: true,
      },
      { text: "Colorectal", isSelected: null },
      { text: "Breast", isSelected: null },
      { text: "Uterine", isSelected: null },
      { text: "Upper GI (Gastric, Small Bowel)", isSelected: null },
      { text: "Upper Urothelial", isSelected: null },
      { text: "Kidney", isSelected: null },
      { text: "Melanoma", isSelected: null },
    ],
    resultPage: 7,
  },
}