import React from "react"
import { useParams, useHistory } from "react-router-dom"
import LIBRARY_CONTENT from "../../pages/library/LIBRARY_CONTENT"
import RectangleLink from "../../components/RectangleLink/RectangleLink"
import Banner from "../Banner/Banner"
import BannerBack from "../Banner/BannerBack/BannerBack"

function Category() {
  const { section } = useParams()
  const history = useHistory()
  const category = LIBRARY_CONTENT.find(
    ({ sectionId }) => section === sectionId
  )

  const flipIcon = (categoryIndex, topicIndex) => {
    const isStartFlipped = categoryIndex % 2 === 0
    if (isStartFlipped) {
      return topicIndex % 2 !== 0
    } else {
      return topicIndex % 2 === 0
    }
  }

  return (
    <div>
      <Banner color={category.color} colorfill flipped>
        <BannerBack
          onClick={() => {
            history.push(`/library`)
          }}
        />
      </Banner>
      <h1>{category.name}</h1>
      <div className='rectangle-listing'>
        {category.topics.map((topic) => {
          return (
            <RectangleLink
              key={topic.name}
              color={category.color}
              linkDest={`/library/category/${category.sectionId}/topic/${topic.topicId}`}
              title={topic.name}
              flipped={flipIcon(category.sectionId, topic.topicId)}
              mdup
            />
          )
        })}
      </div>
    </div>
  )
}

export default Category
