import React from "react"
import Collapsible from "react-collapsible"
import "./Accordion.scss"

function Accordion(props) {
  return (
    <section>
      <div>
        <Collapsible trigger={props.title}>
          <ul>
            {props.text.map((element) => (
              <li key={element} className='collapsible-element'>
                {element}
              </li>
            ))}
          </ul>
        </Collapsible>
      </div>
    </section>
  )
}

export default Accordion
