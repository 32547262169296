import React from "react";

function ArticleNav() {
	return (
		<div>
			<h1>This is ArticleNav</h1>
		</div>
	);
}

export default ArticleNav;
