import React from "react";

function ArticleList() {
	return (
		<div>
			<h1>This is ArticleList</h1>
		</div>
	);
}

export default ArticleList;
