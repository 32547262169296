import React, { Fragment } from "react"

import Header from "./shared/components/Header/Header"
import Footer from "./shared/components/Footer/Footer"
import { Routing } from "./pages"

import "./App.scss"

function App() {
  return (
    <Fragment>
      <Header />
      <main>
        <Routing />
      </main>
      <Footer />
    </Fragment>
  )
}

export default App
