import React from "react"
import { useParams, useHistory } from "react-router-dom"
import LIBRARY_CONTENT from "../../pages/library/LIBRARY_CONTENT"
import NavigationLibraryBottom from "../../components/NavigationLibraryBottom/NavigationLibraryBottom"
import Banner from "../Banner/Banner"
import Accordion from "../Accordion/Accordion"
import BannerBack from "../Banner/BannerBack/BannerBack"
import AudioPlayer from "../AudioPlayer/AudioPlayer"
import { audioFile } from "./audioUtil"

function Topic() {
  const { section, subSection } = useParams()
  const history = useHistory()
  const category = LIBRARY_CONTENT.find(
    ({ sectionId }) => section === sectionId
  )
  const topic = category.topics.find(({ topicId }) => subSection === topicId)

  return (
    <div className={`shadowbox small-full-margin container-${category.color}`}>
      <Banner
        color={category.color}
        flipped={parseInt(topic.topicId) % 2 === 1}
        noPadding
        noMargin
      >
        <BannerBack
          onClick={() =>
            history.push(`/library/category/${category.sectionId}`)
          }
        />
      </Banner>

      <AudioPlayer title={topic.name} song={audioFile(topic.name)} />

      <div>
        {topic.paragraphs.map((paragraph) => {
          return (
            <section>
              <p>{paragraph}</p>
            </section>
          )
        })}
        <div>
          {topic.subTitle && (
            <>
              <h1 id='subtitle'>{topic.subTitle} </h1>
              {topic.accordions.map((accordion) => {
                return (
                  <Accordion title={accordion.title} text={accordion.text} />
                )
              })}
            </>
          )}
          <NavigationLibraryBottom
            pagination={topic.topicId}
            length={category.topics.length}
          />
        </div>
      </div>
    </div>
  )
}

export default Topic
