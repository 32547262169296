import React from "react";

function SearchBar() {
	return (
		<div>
			<h1>This is SearchBar</h1>
		</div>
	);
}

export default SearchBar;
