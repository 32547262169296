import inheritedRisk from "../../assets/audio/S1P1_inherited-risk.mp3"
import familyHistory from "../../assets/audio/S1P2_family-history.mp3"
import geneticPredisposition from "../../assets/audio/S1P3_genetic-predisposition.mp3"
import lifetimeRisk from "../../assets/audio/S1P4_lifetime-risk.mp3"
import geneticCounseling from "../../assets/audio/S2P1_genetic-counseling.mp3"
import geneticTesting from "../../assets/audio/S2P2_genetic-testing.mp3"
import geneticVsGenomic from "../../assets/audio/S2P3_genetic-vs-genomic.mp3"
import cascadeTesting from "../../assets/audio/S2P4_cascade-testing.mp3"
import multigeneTesting from "../../assets/audio/S2P5_multigene-testing.mp3"
import mutations from "../../assets/audio/S2P6_mutations.mp3"
import resultTypes from "../../assets/audio/S3P1_result-types.mp3"
import prostateCancer from "../../assets/audio/S3P2_prostate-cancer-panels.mp3"
import associatedCancerRisksByGeneType from "../../assets/audio/S2P7_associated-cancer-risks-by-gene-type.mp3"
import otherConsiderations from "../../assets/audio/S3P4_other-considerations.mp3"
import additionalRecommendations from "../../assets/audio/S3P5_additional-recommendations.mp3"
import screeningImplications from "../../assets/audio/S4P1_screening-implications.mp3"
import treatmentImplications from "../../assets/audio/S4P2_treatment-implications.mp3"
import discrimination from "../../assets/audio/S5P1_discrimination.mp3"
import reproductiveImplications from "../../assets/audio/S5P2_reproductive-implications.mp3"

export const audioFile = (topic) => {
  switch (topic) {
    case "Inherited Risk":
      return inheritedRisk
    case "Family History":
      return familyHistory
    case "Genetic Predisposition":
      return geneticPredisposition
    case "Lifetime Risk":
      return lifetimeRisk
    case "Genetic Counseling":
      return geneticCounseling
    case "Genetic Testing":
      return geneticTesting
    case "Genetic vs. Genomic Testing":
      return geneticVsGenomic
    case "Family Cascade Testing":
      return cascadeTesting
    case "Multigene Testing":
      return multigeneTesting
    case "Mutations":
      return mutations
    case "Associated Cancer Risks by Gene Type":
      return associatedCancerRisksByGeneType
    case "Result Types":
      return resultTypes
    case "Prostate Cancer Panels":
      return prostateCancer
    case "Other Considerations":
      return otherConsiderations
    case "Additional Reccomendations":
      return additionalRecommendations
    case "Screening Implications":
      return screeningImplications
    case "Treatment Implications":
      return treatmentImplications
    case "Discrimination":
      return discrimination
    case "Reproductive Implications":
      return reproductiveImplications
    default:
      break
  }
}
